import React, { useContext, useState, useEffect } from "react";
import Textarea from "./elements/textarea/Textarea";
import './UploadCollegeFiles.css';
import axios from "axios";
import Master from "./Master";
import { affiliationtype } from "./dropDownOptions";
import { data } from "react-router-dom";

function Assor() {
  const { baseurl, accessorrequestdocs, setaccessorrequestdocs, roleid,
    newaffiliationrequest, setnewaffiliationrequest,
    affiliationrequestid, setaffiliationrequestid, affiliationtypeid,
    userid, academicyearid, affiliationrequestsubmissioncompletion,
    setaffiliationrequestsubmissioncompletion,
    updateshowformsubmissionbutton, showformsubmissionbutton, selectedrequestobject
    , setselectedrequestobject, isloading, setisloading
  } = useContext(Master);


  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disablemovetonextstepbutton, setdisablemovetonextstepbutton] = useState(true);
  const [displaysuccessmessage, setdisplaysuccessmessage] = useState("");
  const [assignedassessordetails, setassignedassessordetails] = useState({});
  // const [disablemovetonextstepbutton, setshowmovetonextstepbutton] = useState(null);
  const [disablerequestformoreinformationbutton, setdisablerequestformoreinformationbutton] = useState(true);
  const [documentapprovalselection,setdocumentapprovalselection]= useState(null)
  const handlefilechange = async (e, fileid, index) => {

    const updateddata = [...accessorrequestdocs];
    updateddata[index].file = e.target.files[0];
    updateddata[index].fileselected = true;
    updateddata[index].uploadstatus = "File Selected but not uploaded";
    updateddata[index].approvalstatus = "Action Pending";
    updateddata[index].comment = "";
    setaccessorrequestdocs(updateddata);


  };
  const handletextareachange = (index, comment) => {
    // setformdata((prevData) => ({ ...prevData, [name]: value }));

    const updateddata = [...accessorrequestdocs];
    updateddata[index].rejectedcomment = comment;
    setaccessorrequestdocs(updateddata);


  };

  const handleradiochange = (index, value) => {


    const updateddata = [...accessorrequestdocs];
    updateddata[index].documentapprovalstatus = value;

    if(value==1){
      updateddata[index].documentactionstatus = "Action Pending - Please confirm approval";
    }
    else{
      updateddata[index].documentactionstatus = "Action Pending - Please reupload email ";
    }
    setdocumentapprovalselection(value)
    setaccessorrequestdocs(updateddata);
    setdisablemovetonextstepbutton(true);
    setdisablerequestformoreinformationbutton(true);

  };




  const handlemovetonextstep = async (status) => {
    // Prepare your form data or any necessary data for the request

    axios({
      url: baseurl + '/assessorstatus',
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {

        "affiliationrequestid": Object.keys(selectedrequestobject) == 0 ? affiliationrequestid : selectedrequestobject.requestid,
        "userid": parseInt(userid),
        "status": status,
      }

    })
      .then((response) => {


        if (response.data.success) {
          // alert("Request status updated successfully");
          setSuccessMessage(true);
          setErrorMessage(false)
          //  setshowrequestsubmit(false);
          setdisplaysuccessmessage(`Request ID ${response.data.affiliationrequestid} has been Approved for Next step!`)
          setselectedrequestobject((prevData) => ({ ...prevData, ['status']: 1 }));

        }
        else {
          //throw new Error("Failed to update request status");
          // setErrorMessage(response.data.message);
          // alert("Expected error")
          setSuccessMessage(false);
          setErrorMessage(true)
          setdisplaysuccessmessage(`Unexpected error occurred!`);
        }
      })
      .catch((error) => {
        console.error("Error updating request status:", error.response?.data || error.message);
        // setSuccessMessage("Error occurred during submission. Please try again.");
        //setTimeout(() => setSuccessMessage(""), 5000); // Clear the message after 5 seconds
        setSuccessMessage(false);
        setErrorMessage(true)
        setdisplaysuccessmessage(`Unexpected error occurred!`);
      });
  };








  useEffect(() => {
    setaffiliationrequestsubmissioncompletion((prevData) => ({ ...prevData, ['documentssubmissionscomplete']: false }))
    setisloading(true);

    axios({
      url: baseurl + '/assessmenttdoc',
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: { affiliationrequestid: Object.keys(selectedrequestobject).length > 0 ? selectedrequestobject.requestid : null }

    }).then((response) => {

      if (response.data.success) {
        var responsedata = response.data.data.assessordoc;
        responsedata.forEach(function (element) {
          element.file = null
          element.fileselected = false;
          element.filechanged = false;
          element.fileuploaded = false;
          element.uploadsuccess = false;
          element.uploadstatus = " File not selected";
          element.documentactionstatus = "";
          // element.approvalstatus = "Action Pending";
          element.comment = "";
          element.rejectedcomment = "";
          element.uploadeddocumentstatus = "Action Pending";

        });


        setaccessorrequestdocs(responsedata)


        if (response.data.data.assessordoc.length == 0) {
          setselectedrequestobject((prevdata) => ({ ...prevdata, paymentaffiliation: false }))
        }
        if (response.data.data.assessordoc.length == 1) {

          if (response.data.data.assessordoc[0].status == 1) {
            setselectedrequestobject((prevdata) => ({ ...prevdata, paymentaffiliation: true }))
            // setdisablsubmitreportbutton(true);
          }
          
         
          
        }

        if(response.data.data.assignedassessor.length>=1){

          setassignedassessordetails(response.data.data.assignedassessor[0]);

          if(response.data.data.assignedassessor[0].status==process.env.REACT_APP_STATUS_ACCEPTED){

            if(selectedrequestobject.requesttypeid==process.env.REACT_APP_PROVISIONAL_AFFILIATION){
              setselectedrequestobject((prevdata) => ({ ...prevdata, showfinalcertificate: true }))
              
            }else{
              setselectedrequestobject((prevdata) => ({ ...prevdata, paymentaffiliation: true }))
            }

            
        }else{


        }


      }



      }
    })
      .catch((error) => {
        // alert("Unexpected Error!")


      })
      .finally(() => {
        setisloading(false);
      })

  }, []);


  function handledownloadsubmittedfile(fileid, index) {

    axios({
      url: baseurl + '/filedownload',
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: 'blob',
      // data: {} 
      data: {
        fileid: fileid,
        affiliationrequestid: selectedrequestobject.requestid,
      }

    }).then((response) => {

      const contentDisposition = response.headers["content-disposition"];
      let fileName = "downloaded_file"; // Default file name
      // if (contentDisposition) {
      //   const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      //   if (fileNameMatch && fileNameMatch[1]) {
      //     fileName = fileNameMatch[1];
      //   }
      // }
      if (contentDisposition) {
        // Improved regex to handle filenames with or without quotes
        const fileNameMatch = contentDisposition.match(/filename[^;=\n]=(['"]?)(.?)\1(?:;|$)/);
        if (fileNameMatch && fileNameMatch[2]) {
          fileName = decodeURIComponent(fileNameMatch[2]);
        }
      }

      // Create a blob from the response data
      const blob = new Blob([response.data], { type: response.headers["content-type"] });

      // Create a link element to trigger file download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();

    })
      .catch((error) => {
        // alert("Unexpected Error!")


      });


  }


  function handlefileapprovalstatus(index) {


    const updateddata = [...accessorrequestdocs];
    updateddata[index].documentactionstatus = "updating.......";
    setaccessorrequestdocs(updateddata);


    axios({
      url: baseurl + '/updatefilestatus',
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {

        "affiliationrequestid": selectedrequestobject.requestid,
        "userid": parseInt(userid),
        "fileid": accessorrequestdocs[index].id,
        "status": parseInt(accessorrequestdocs[index].documentapprovalstatus),
        "comment": accessorrequestdocs[index].rejectedcomment,
      }

    })

      .then((response) => {


        if (response.data.success) {
          // Display success message with request ID
          // setSuccessMessage(`Request ID ${response.data.affiliationrequestid} has been successfully submitted!`);
          //setTimeout(() => setSuccessMessage(""), 5000); // Clear the message after 5 seconds
          // setSuccessMessage(true);
          // setshowrequestsubmit(false);
          const updateddata = [...accessorrequestdocs];
          if (updateddata[index].documentapprovalstatus == 1) {
            updateddata[index].documentactionstatus = "Approved";
            setdisablerequestformoreinformationbutton(true);
            setdisablemovetonextstepbutton(false);

          } else {
            updateddata[index].documentactionstatus = "Email Sent for more information";
            setdisablerequestformoreinformationbutton(false);
            setdisablemovetonextstepbutton(true);
          }

          setaccessorrequestdocs(updateddata);
        }
        else {
          const updateddata = [...accessorrequestdocs];
          updateddata[index].documentactionstatus = "Unexpected Error/ please try again";
          setaccessorrequestdocs(updateddata);
          //throw new Error("Failed to update request status");
          setErrorMessage(response.data.message);
        }

        checkmovetonextstepbutton();
      })
      .catch((error) => {
        console.error("Error updating request status:", error.response?.data || error.message);
        setSuccessMessage("Error occurred during submission. Please try again.");
        //setTimeout(() => setSuccessMessage(""), 5000); // Clear the message after 5 seconds
      });



  }


  async function handleuploadselectedfile(id, index) {


    ///////////////////////////////////////////////////

    const formData = new FormData();
    formData.append("file", accessorrequestdocs[index].file);
    formData.append("fileid", accessorrequestdocs[index].id);
    formData.append("affiliationtypeid", affiliationtypeid);
    formData.append("newaffiliationrequest", newaffiliationrequest);
    formData.append("affiliationrequestid", selectedrequestobject.requestid);
    formData.append("userid", userid);
    formData.append("academicyearid", academicyearid);
    formData.append("roleid", roleid);
    setisloading(true);
    // userid,academicyearid
    try {

      // setUploadStatus("Uploading...");
      const updateddata = [...accessorrequestdocs];
      updateddata[index].uploadstatus = "Uploading...";
      setaccessorrequestdocs(updateddata);


      const res = await axios.post(baseurl + '/fileupload', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setisloading(false);

      if (res.data.success) {
        setaffiliationrequestid(res.data.data.requestid);

        const updateddata = [...accessorrequestdocs];
        updateddata[index].uploadstatus = "Upload Success";
        updateddata[index].fileuploaded = true;
        updateddata[index].uploadsuccess = true;
        setaccessorrequestdocs(updateddata);

      }
      // setUploadStatus("File uploaded successfully!");

    } catch (error) {
      setisloading(false);
      const updateddata = [...accessorrequestdocs];
      updateddata[index].uploadstatus = "Error in file upload";
      updateddata[index].fileuploaded = true;
      updateddata[index].uploadsuccess = false;
      setaccessorrequestdocs(updateddata);
      setSuccessMessage("File uploaded successfully!");
      setTimeout(() => setSuccessMessage(""), 5000);


      console.error("File upload error:", error.response?.data || error.message);
      // setUploadStatus("File upload failed.");
    }
    setisloading(false);
    var documentssubmissionscomplete = checkifallFilesUploaded();
    setaffiliationrequestsubmissioncompletion((prevData) => ({ ...prevData, ['documentssubmissionscomplete']: documentssubmissionscomplete }))

  }



  const checkifallFilesUploaded = () => {

    var showsubmitbutton = true;

    for (let index = 0; index < accessorrequestdocs.length; index++) {
      const element = accessorrequestdocs[index];
      if (element.uploadsuccess == false) {
        showsubmitbutton = false;
      }
    }

    // setshowrequestsubmit(showsubmitbutton);
    return showsubmitbutton;
  };


  function checkmovetonextstepbutton() {



    var disable = false;
    for (let index = 0; index < accessorrequestdocs.length; index++) {
      const element = accessorrequestdocs[index];
      if ((element.documentactionstatus !== 'Approved') || (element.hasOwnProperty('documentactionstatus') == false)) {


        disable = true;
      }
    }
    setdisablemovetonextstepbutton(disable);

  }





  useEffect(() => {

    updateshowformsubmissionbutton();

  }, [affiliationrequestsubmissioncompletion]);






  return (
    <>
    {(roleid == process.env.REACT_APP_ASSESSOR || roleid == process.env.REACT_APP_ADMIN) &&
    <div>
        <h3>Assessor Details</h3>
      <div className="assessordetails">
    
      <span>Name: {assignedassessordetails.name}</span>
      <span>Department: {assignedassessordetails.collegename}</span>
      <span>Email: {assignedassessordetails.department}</span>
      <span>Designation: {assignedassessordetails.designation}</span>
      <span>Mobile: {assignedassessordetails.mobile_no}</span>
      </div>
      <div className="fileUploads">
        {/* File Upload Sections */}
        {
          accessorrequestdocs.map(({ documentactionstatus, documentapprovalstatus, 
            rejectedcomment, uploadstatus, approvalstatus, id, name, haslink, link,
             comment, status,fileselected, accept = ".pdf,.docx,.doc" }, index) => (

            ///////////////////////////////////////////////////////////////

            <div key={name} className="file-upload">
              <h3>{name}</h3>
              <div className="downloadbuttons">
                {haslink == 1 && <>
                  <a href={link} target="_blank" download>
                    <button className="templatedownload">Download Template</button>
                  </a>
                </>
                }


              </div>

              {/* /////////////////////////////////////////////////////////////// */}


              {/*///////////////////////////////////////////////////////////  */}

              <br />
              {

               ((roleid == process.env.REACT_APP_ASSESSOR) && (assignedassessordetails.status == process.env.REACT_APP_CORRECTION ||assignedassessordetails.status == null ))


                &&
                <input type="file" accept={accept} onChange={(e) => handlefilechange(e, id, index)} />
              }

              {/* //////////////////////////////////////////////////////////// */}
              <br />

              {

                (assignedassessordetails.status == process.env.REACT_APP_CORRECTION ||assignedassessordetails.status == null)


                && <>
                  <div className="downloadbuttons">
                    <button className="bluebutton" disabled={!fileselected} onClick={() => handleuploadselectedfile(id, index)}>Upload selected File</button>
                  </div>
                  <span> Status: {uploadstatus} </span>
                </>
              }
              {/* ///////////////////////////////////////////////////////////////////// */}

              {/* ////////////////////////////////////////////////////////////////////////////// */}

              <div className="downloadbuttons">
                {

                  (assignedassessordetails.status == process.env.REACT_APP_SUBMITTED||assignedassessordetails.status == process.env.REACT_APP_RESUBMISSION) &&
                  <>
                    <button className="templatedownload" onClick={() => handledownloadsubmittedfile(id, index)}>Download Submitted File</button>
                  </>
                }


              </div>



              {/* /////////////////////////////////////////////////////////////////// */}

              {(roleid == process.env.REACT_APP_ADMIN &&
                Object.keys(selectedrequestobject).length > 0
                &&
                (assignedassessordetails.status == process.env.REACT_APP_SUBMITTED || assignedassessordetails.status == process.env.REACT_APP_CORRECTION))
                &&
                <div className="formradioinput">
                  <input type="radio" name={name} value="1" checked={documentapprovalstatus === "1"} onChange={(e) => handleradiochange(index, e.target.value)} />
                  <label>Approved</label>
                  <input type="radio" name={name} value="0" checked={documentapprovalstatus === "0"} onChange={(e) => handleradiochange(index, e.target.value)} />
                  <label>Request more information</label>
                </div>
              }
              {documentapprovalstatus == "0" && (
                <Textarea
                  placeholder={`Add comments for ${name}`}
                  name={name}
                  value={rejectedcomment}
                  update={(value) => handletextareachange(index, value)}
                />
              )}
              <div className="downloadbuttons">
                {((documentapprovalstatus === "1") || (documentapprovalstatus === "0")) && <>
                  <button className="templatedownload"
                   onClick={() => handlefileapprovalstatus(index)}>{documentapprovalstatus === "1" ? "Confirm Approval" : "Send Reupload Email"}</button><br />
                  <span> Status: {documentactionstatus == "" ? "Action Pending" : documentactionstatus} </span>
                </>

                }
              </div>


            </div>
          ))}

      </div>

      <div className="Confirmandsubmit">


        {(roleid == process.env.REACT_APP_ASSESSOR && ( assignedassessordetails.status == null)) &&
          <div >
            <button className="greenbutton" onClick={() => handlemovetonextstep(process.env.REACT_APP_SUBMITTED)}
              disabled={!showformsubmissionbutton}
            // className="uploadfilesumitbtn"
            >Submit Report</button>

          </div>
        }

{(roleid == process.env.REACT_APP_ADMIN && (Object.keys(selectedrequestobject).length > 0) &&

(
 assignedassessordetails.status == process.env.REACT_APP_SUBMITTED || assignedassessordetails.status == process.env.REACT_APP_RESUBMISSION)) &&

          <div >
            <button className="redbutton" onClick={() => handlemovetonextstep(process.env.REACT_APP_REJECTED)}
              //  disabled={!allFilesUploaded()}
              // disabled={!disablemovetonextstepbutton}
            // className="uploadfilesumitbtn"
            >Reject Application</button>
          </div>
          
        }

        {(roleid == process.env.REACT_APP_ADMIN && (Object.keys(selectedrequestobject).length > 0)) &&

         (assignedassessordetails.status == process.env.REACT_APP_SUBMITTED|| assignedassessordetails.status == process.env.REACT_APP_RESUBMISSION) &&
         (documentapprovalselection == 0 ) &&
          <div >
            <button className="greenbutton" onClick={() => handlemovetonextstep(process.env.REACT_APP_CORRECTION)}
              //  disabled={!allFilesUploaded()}
              disabled={disablerequestformoreinformationbutton}
            // className="uploadfilesumitbtn"
            >Request More Information</button>
          </div>
        }



{(roleid == process.env.REACT_APP_ADMIN && (Object.keys(selectedrequestobject).length > 0)) &&

(assignedassessordetails.status == process.env.REACT_APP_SUBMITTED|| assignedassessordetails.status == process.env.REACT_APP_RESUBMISSION) &&
(documentapprovalselection == 1 ) &&
          <div >
            <button className="greenbutton" onClick={() => handlemovetonextstep(process.env.REACT_APP_ACCEPTED)}
              //  disabled={!allFilesUploaded()}
              disabled={disablemovetonextstepbutton  }
            // className="uploadfilesumitbtn"
            >Move to next step</button>
          </div>
        }




        {(roleid == process.env.REACT_APP_ASSESSOR && (Object.keys(selectedrequestobject).length > 0) && assignedassessordetails.status == process.env.REACT_APP_CORRECTION) &&
          <div >
            <button className="greenbutton" onClick={() => handlemovetonextstep(process.env.REACT_APP_RESUBMISSION)}
              disabled={!showformsubmissionbutton}
            // className="uploadfilesumitbtn"
            >Submit With Corrections</button>

          </div>
        }

      </div>

      {successMessage && (
        <p className="successmessage">{displaysuccessmessage}</p>
      )}
      {errorMessage && (
        <p className="errormessage">{displaysuccessmessage}</p>
      )}



    </div>
}
    </>
  );
}

export default Assor;
