import React, { useContext, useState, useEffect } from 'react';
import Master from './Master';
import { useNavigate } from "react-router-dom";
import './Sidebar.css';

const Sidebar = ({ role }) => {

  const {
     roleid,setselectedrequestobject
  } = useContext(Master);

  
  const navigate = useNavigate();



  const adminoptions = [
    { name: 'Configuration', path: '/configuration' },
    { name: 'Requests List', path: '/home' },
  ];

  const useroptions = [
    // { name: 'Profile', path: '/profile' },
    { name: 'Request List', path: '/home' },
  ];

  const assessoroptions=[
    { name: 'Requests List', path: '/home' }
  ]
  const [selectedoption, setselectedoption] = useState('');

  const handleoptionclick = (optionname,optionpath) => {
    setselectedoption(optionname);
    if(optionpath==="/home"){
      setselectedrequestobject({})
    }
    navigate(optionpath,{replace:true});

  };
  

  const renderoptions = () => {


    if (roleid ==3) {
      return adminoptions.map((option) => (
        <button
          key={option.name}
          className={`sidebar-item ${selectedoption === option.name ? 'active' : ''}`}
          onClick={() => handleoptionclick(option.name,option.path)}
        >
          <h3>{option.name}</h3>
        </button  >
      ));
    }

    if (roleid ==1) {
      return useroptions.map((option) => (
       <div className='prflbtnreqbtn'>
       <a href="./home"><button
          key={option.name}
          className={`sidebar-item ${selectedoption === option.name ? 'active' : ''}`}
          // onClick={() =>{ handleoptionclick(option.name,option.path);}}
        >
          <h3>{option.name}</h3>
        </button></a></div>
      ));
    }

    if (roleid ==2) {
      return assessoroptions.map((option) => (
        <button
          key={option.name}
          className={`sidebar-item ${selectedoption === option.name ? 'active' : ''}`}
          onClick={() => handleoptionclick(option.name,option.path)}
        >
          <h3>{option.name}</h3>
        </button  >
      ));
    }



    return null;
  };
  
  return (
    <div className="sidebar">
      <ul className="sidebar-menu">{renderoptions()}</ul>
    </div>
  );
};

export default Sidebar;
