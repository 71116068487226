import React, { useEffect, useContext } from 'react';
import Master from './Master';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './Profile.css';

const Profile = () => {
  const navigate = useNavigate();

  const { 
    userid, 
    setregistrationformvalidationdata, 
    registrationformvalidationdata, 
    baseurl, 
    setroleid,
    roleid,
    selectedrequestobject,
    managementtype,
    setmanagementtype, isloading, setisloading
  } = useContext(Master);



function builddataforapi(){

  if(roleid==process.env.REACT_APP_COLLEGE){
    return{
      userid:userid,
      roleid:roleid,}
  }else{
    return({ userid:selectedrequestobject.userid})
    
    }

}


  useEffect(() => {
    setisloading(true);
    const fetchProfileData = async () => {
      try {




        axios({
          url: baseurl+"/getprofile",
          method: "POST",
          headers: {
              authorization: `Bearer ${localStorage.getItem("token")}`  ,
          },
          data: builddataforapi(),
          
      }
    ).then((response) => {
      if (response.data.success) {
        const { collegeDetails, roleid, token, managementtype } = response.data.data;

        // Update localStorage and state
        // localStorage.setItem("accesstoken", token);
        // localStorage.setItem("roleid", roleid);
        setregistrationformvalidationdata(collegeDetails);
        setmanagementtype(managementtype);
        // setroleid(roleid);

        // Navigate to home page
        navigate("/home");
      } else {
        console.error("Failed to fetch profile data");
      }
      
    }).catch((error) => {
      console.error("Error during API call:", error);
    });


      } catch (error) {
        console.error("Error during API call:", error);
      }
      finally{
        setisloading(false);
      }
    };

    // Fetch profile data on component mount
    if (userid) {
      fetchProfileData();
    }
  }, [userid, baseurl, navigate, setregistrationformvalidationdata, setroleid]);

  return (
    <div className="profile">
      <h3>Profile</h3>
      {registrationformvalidationdata && (
        <>
          <div className="profile-field">
            <span className="label">College Name:</span>
            <span>{registrationformvalidationdata.collegename}</span>
          </div>
          <div className="profile-field">
            <span className="label">Management Type:</span>
            <span>{managementtype}</span>
          </div>
          <div className="profile-field">
            <span className="label">Registration Number:</span>
            <span>{registrationformvalidationdata.registrationnumber}</span>
          </div>
          <div className="profile-field">
            <span className="label">Address:</span>
            <span>{registrationformvalidationdata.address}</span>
          </div>
          <div className="profile-field">
            <span className="label">Telephone:</span>
            <span>{registrationformvalidationdata.telephone}</span>
          </div>
          <div className="profile-field">
            <span className="label">Email:</span>
            <span>{registrationformvalidationdata.email}</span>
          </div>
          <div className="profile-field">
            <span className="label">Principal Mobile:</span>
            <span>{registrationformvalidationdata.principalmobile}</span>
          </div>
          <div className="profile-field">
            <span className="label">Website URL:</span>
            <span>{registrationformvalidationdata.websiteurl}</span>
          </div>
        </>
      )}
      {isloading && (
  <div className="loading-overlay">
    <div className="spinner"></div>
  </div>
)}
    </div>
  );
};

export default Profile;
