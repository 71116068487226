import React, { useContext, useState, useEffect } from "react";
import Textarea from "./elements/textarea/Textarea";
import './UploadCollegeFiles.css';
import axios from "axios";
import Master from "./Master";
import { affiliationtype } from "./dropDownOptions";
import { data } from "react-router-dom";

function Finalcertificate() {
  const { baseurl, finalcertificatedoc, setfinalcertificatedoc, roleid,
    newaffiliationrequest, setnewaffiliationrequest,
    affiliationrequestid, setaffiliationrequestid, affiliationtypeid, 
    userid, academicyearid,affiliationrequestsubmissioncompletion, 
    setaffiliationrequestsubmissioncompletion,
    updateshowformsubmissionbutton,showformsubmissionbutton,selectedrequestobject
    ,setselectedrequestobject
  } = useContext(Master);

  ///// finalcertificatedoc, setfinalcertificatedoc,


  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disablesubmitcertificatebutton,setdisablesubmitcertificatebutton]=useState(true);
  const [displaysuccessmessage,setdisplaysuccessmessage]=useState("");

  const handlefilechange = async (e, fileid, index) => {

    const updateddata = [...finalcertificatedoc];
    updateddata[index].file = e.target.files[0];
    updateddata[index].fileselected = true;
    updateddata[index].uploadstatus = "File Selected but not uploaded";
    updateddata[index].approvalstatus = "Action Pending";
    updateddata[index].comment = "";
    updateddata[index].disableuploadbutton = false;
    setfinalcertificatedoc(updateddata);
  
    // setdisablesubmitcertificatebutton(false);

  };
  const handletextareachange = (index, comment) => {
    // setformdata((prevData) => ({ ...prevData, [name]: value }));

    const updateddata = [...finalcertificatedoc];
    updateddata[index].rejectedcomment = comment;
    setfinalcertificatedoc(updateddata);


  };

  const  handleradiochange = (index, value) => {


    const updateddata = [...finalcertificatedoc];
    updateddata[index].documentapprovalstatus = value;

    setfinalcertificatedoc(updateddata);

  };


  const handlemovetonextstep = async () => {
    // Prepare your form data or any necessary data for the request

      axios({
        url: baseurl + '/updaterequeststatus',
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data:{
              
          "affiliationrequestid":selectedrequestobject.requestid,
          "userid":parseInt(userid),
         "status":0,
        }
      
      })



      .then((response) => {

        if (response.data.success) {
          alert("Request status updated successfully");

    setselectedrequestobject((prevData) => ({ ...prevData, ['status']: 0 }));

        } 
        else {
          //throw new Error("Failed to update request status");
          // setErrorMessage(response.data.message);
          alert("Expected error")
        }
      })
      .catch((error) => {
        console.error("Error updating request status:", error.response?.data || error.message);
        //setSuccessMessage("Error occurred during submission. Please try again.");
        //setTimeout(() => setSuccessMessage(""), 5000); // Clear the message after 5 seconds
      });
  };
  
  



  

  useEffect(() => {
    // setaffiliationrequestsubmissioncompletion((prevData) =>({ ...prevData, ['documentssubmissionscomplete']: false }))

    axios({
      url: baseurl + '/finalcertificatedoc',
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
            data: { affiliationrequestid: Object.keys(selectedrequestobject).length > 0 ? selectedrequestobject.requestid : null }
      

    }).then((response) => {


      if (response.data.success) {
        var responsedata = response.data.data;

        responsedata.forEach(function (element) {
          element.file = null
          element.fileselected = false;
          element.filechanged = false;
          element.fileuploaded = false;
          element.uploadsuccess = false;
          element.uploadstatus = " File not selected";
          element.documentactionstatus = "";
          element.approvalstatus = "Action Pending";
          element.comment = "";
          element.rejectedcomment = "";
          element.uploadeddocumentstatus = "Action Pending";
          element.disableuploadbutton = true;

        });

        if(responsedata[0].status==1)
          {
            setdisablesubmitcertificatebutton(true);
           
          }else{
            setdisablesubmitcertificatebutton(false);
          }
        setfinalcertificatedoc(responsedata)
      }
    })
      .catch((error) => {
        // alert("Unexpected Error!")


      });

  }, []);

  




  function handledownloadsubmittedfile(fileid, index) {

    axios({
      url: baseurl + '/filedownload',
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: 'blob',
      // data: {} 
      data: {
        fileid: fileid,
        affiliationrequestid: selectedrequestobject.requestid,
      }

    }).then((response) => {

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', finalcertificatedoc[index].name);
      document.body.appendChild(link);
      link.click();

      // if (response.data.success) {
      //   var responsedata = response.data.data;
      //   responsedata.forEach(function (element) {
      //     element.comment = "";
      //   });
      // }


    })
      .catch((error) => {
        alert("Unexpected Error!")


      });


  }


  function handlefileapprovalstatus(index, approvalstatus) {

axios({
  url: baseurl + '/updatefilestatus',
  method: "POST",
  headers: {
    authorization: `Bearer ${localStorage.getItem("token")}`,
  },
  data:{
        
    "affiliationrequestid":selectedrequestobject.requestid,
    "userid":parseInt(userid),
    "fileid":finalcertificatedoc[index].id,
   "status":parseInt (finalcertificatedoc[index].documentapprovalstatus),
    "comment": finalcertificatedoc[index].rejectedcomment,
  }

})




    // axios
    // .post(`${baseurl}/updatefilestatus`,{
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    //   data:{
        
    //     "affiliationrequestid":affiliationrequestid,
    //     "userid":userid,
    //     "fileid":finalcertificatedoc[index].fileid,
    //    "status":parseInt (finalcertificatedoc[index].documentapprovalstatus),
    //     "reason": finalcertificatedoc[index].rejectedcomment,
    //   }
      
      
    // })
    .then((response) => {
      if (response.data.success) {
        // Display success message with request ID
       // setSuccessMessage(`Request ID ${response.data.affiliationrequestid} has been successfully submitted!`);
        //setTimeout(() => setSuccessMessage(""), 5000); // Clear the message after 5 seconds
        // setSuccessMessage(true);
        // setshowrequestsubmit(false);
      } 
      else {
        //throw new Error("Failed to update request status");
        setErrorMessage(response.data.message);
      }
    })
    .catch((error) => {
      console.error("Error updating request status:", error.response?.data || error.message);
      //setSuccessMessage("Error occurred during submission. Please try again.");
      //setTimeout(() => setSuccessMessage(""), 5000); // Clear the message after 5 seconds
    });



  }




  function handlesubmitaffiliationrequest(id, index) {

    
////////////////////////////////////////////////////////////////////


   // Prepare your form data or any necessary data for the request
   const formData = new FormData();
   formData.append("affiliationrequestid", selectedrequestobject.requestid);
   formData.append("userid", userid);
   formData.append("roleid", roleid);
   formData.append("status", 0); // Adjust based on your API requirements
 
   // Send the request
   axios({
    url: baseurl + '/updaterequeststatus',
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data:{
          
      "affiliationrequestid":selectedrequestobject.requestid,
      "userid":parseInt(userid),
     "status":1,
    }
  
  })






     .then((response) => {
      //  setdisablesubmitcertificatebutton(true);
       if (response.data.success) {
         // Display success message with request ID
        // setSuccessMessage(`Request ID ${response.data.affiliationrequestid} has been successfully submitted!`);
         //setTimeout(() => setSuccessMessage(""), 5000); // Clear the message after 5 seconds
        //  setSuccessMessage(true);
        //  setshowrequestsubmit(false);
        // setdisablesubmitcertificatebutton(true);
        setdisablesubmitcertificatebutton(true)
       } 
       else {
         //throw new Error("Failed to update request status");
         setErrorMessage(response.data.message);
       }
     })
     .catch((error) => {
       console.error("Error updating request status:", error.response?.data || error.message);
       //setSuccessMessage("Error occurred during submission. Please try again.");
       //setTimeout(() => setSuccessMessage(""), 5000); // Clear the message after 5 seconds
     });

     ///////////////////////////////////////////////////////




  }


  async function handleuploadselectedfile(id, index) {


    ///////////////////////////////////////////////////

    const formData = new FormData();
    formData.append("file", finalcertificatedoc[index].file);
    formData.append("fileid", finalcertificatedoc[index].id);
    formData.append("affiliationtypeid", selectedrequestobject.affiliationtypeid);
    formData.append("newaffiliationrequest", newaffiliationrequest);
    formData.append("affiliationrequestid", selectedrequestobject.requestid);
    formData.append("userid", userid);
    formData.append("academicyearid", academicyearid);
    formData.append("roleid", roleid);
    // userid,academicyearid
    try {

      // setUploadStatus("Uploading...");
      const updateddata = [...finalcertificatedoc];
      updateddata[index].uploadstatus = "Uploading...";

      setfinalcertificatedoc(updateddata);


      const res = await axios.post(baseurl + '/fileupload', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.data.success) {
        setaffiliationrequestid(res.data.data.requestid);
        setdisablesubmitcertificatebutton(false);
        const updateddata = [...finalcertificatedoc];
        updateddata[index].uploadstatus = "Upload Success";
        updateddata[index].fileuploaded = true;
        updateddata[index].uploadsuccess = true;
        updateddata[index].disableuploadbutton = true;
        setfinalcertificatedoc(updateddata);

      }
      // setUploadStatus("File uploaded successfully!");

    } catch (error) {

      const updateddata = [...finalcertificatedoc];
      updateddata[index].uploadstatus = "Error in file upload";
      updateddata[index].fileuploaded = true;
      updateddata[index].uploadsuccess = false;
      setdisablesubmitcertificatebutton(false);

      setfinalcertificatedoc(updateddata);
      setSuccessMessage("File uploaded successfully!");
        setTimeout(() => setSuccessMessage(""), 5000);


      console.error("File upload error:", error.response?.data || error.message);
      // setUploadStatus("File upload failed.");
    }
    var documentssubmissionscomplete=allFilesUploaded();
    setaffiliationrequestsubmissioncompletion((prevData) =>({ ...prevData, ['documentssubmissionscomplete']: documentssubmissionscomplete }))

  }



  const allFilesUploaded = () => {

    var showsubmitbutton = true;

    for (let index = 0; index < finalcertificatedoc.length; index++) {
      const element = finalcertificatedoc[index];
      if (element.uploadsuccess == false) {
        showsubmitbutton = false;
      }
    }

    // setshowrequestsubmit(showsubmitbutton);
    return showsubmitbutton;
  };

  

  useEffect(() => {

    updateshowformsubmissionbutton();

  }, [affiliationrequestsubmissioncompletion]);


  
  




  return (
    <div className="mainwrapper">
    <div className="assessorfile">
      <h3 className="heading">Final Certificate</h3>
      <div className="fileUploads">
        {/* File Upload Sections */}
        {
          finalcertificatedoc?.map(({disableuploadbutton,documentactionstatus,status,documentapprovalstatus, rejectedcomment,uploadstatus, approvalstatus, id, name, haslink, link, comment, accept = ".pdf,.docx,.doc" }, index) => (

            
          
            

            <div key={name} className="file-upload">

              <div className="downloadbuttons">
                {(haslink == 1 && roleid == process.env.REACT_APP_ADMIN) && <>
                  <a href={link} target="_blank" download>
                    <button className="templatedownload">Download Template</button>
                  </a>
                </>
                }


              </div>

              {/* /////////////////////////////////////////////////////////////// */}


              {/*///////////////////////////////////////////////////////////  */}


              {(roleid == process.env.REACT_APP_ADMIN) &&
                <input type="file" accept={accept} onChange={(e) => handlefilechange(e, id, index)} />
              }

              {/* //////////////////////////////////////////////////////////// */}

              {(roleid == process.env.REACT_APP_ADMIN) && <>
                <div className="downloadbuttons">
                  <button className="templatedownload"
                   onClick={() => handleuploadselectedfile(id, index)}
                   disabled={disableuploadbutton}
                   >Upload certificate</button>
                </div>
                <span> Status: {uploadstatus} </span>
              </>
              }
              {/* ///////////////////////////////////////////////////////////////////// */}

              {/* ////////////////////////////////////////////////////////////////////////////// */}

              <div className="downloadbuttons">
                {

                  (Object.keys(selectedrequestobject).length>0 && ((roleid == process.env.REACT_APP_COLLEGE)|| (roleid == process.env.REACT_APP_ADMIN))) &&
                  <>
                  {status==1 &&
                    <button className="templatedownload" onClick={() => handledownloadsubmittedfile(id,index)}>Download Certificate</button>
                  }
                 { status!=1 &&<>
                 { roleid == process.env.REACT_APP_COLLEGE &&
                 <h3>Your Certificate is being processed</h3>
                 }
                { roleid == process.env.REACT_APP_ADMIN &&
                 <h3>Please upload Final Certificate</h3>
                 }
                 </>
                }
                  </>
                }


              </div>



              {/* /////////////////////////////////////////////////////////////////// */}

              {/* {(roleid == process.env.REACT_APP_COLLEGE && Object.keys(selectedrequestobject).length>0 ) &&
                <div className="formradioinput">
                  <input type="radio" name={name} value="1" checked={documentapprovalstatus === "1"} onChange={(e) => handleradiochange(index,e.target.value)} />
                  <label>Approved</label>
                  <input type="radio" name={name} value="0" checked={documentapprovalstatus === "0"} onChange={(e) => handleradiochange(index, e.target.value)} />
                  <label>Email for document reupload</label>
                </div>
              } */}
              {/* {documentapprovalstatus === "0" && (
                <Textarea
                  placeholder={`Add comments for ${name}`}
                  name={name}
                  value={rejectedcomment}
                  update={(value) => handletextareachange(index, value)}
                />
              )} */}
                {/* <div className="downloadbuttons">
                  {((documentapprovalstatus === "1") ||(documentapprovalstatus === "0") ) &&<>
                  <button className="templatedownload" onClick={() => handlefileapprovalstatus(index)}>{documentapprovalstatus === "1" ? "Confirm Approval" : "Send Reupload Email"}</button><br/>
                  <span> Status: {documentactionstatus==""?"Action Pending":documentactionstatus} </span>
                  </>

                  }
                </div> */}


            </div>
          ))}

      </div>





          


      {/* {(roleid == process.env.REACT_APP_COLLEGE && (Object.keys(selectedrequestobject).length>0) &&  selectedrequestobject.status==0) &&
        <div className="uploadfilesubmit">
          <button onClick={handlemovetonextstep}
            //  disabled={!allFilesUploaded()}
            className="uploadfilesumitbtn"
          >Move To Next Step</button>
        </div>
      } */}

      

      {successMessage && (
    <p className="successmessage">{displaysuccessmessage}</p>
)}
{errorMessage&&(
  <p className="errormessage">{displaysuccessmessage}</p>
)}


</div>
<div className="Confirmandsubmit">


      {(roleid == process.env.REACT_APP_ADMIN  && (Object.keys(selectedrequestobject).length>0 )) &&
        <div className="uploadfilesubmit">
          <button onClick={handlesubmitaffiliationrequest}
             disabled={disablesubmitcertificatebutton}
            className="greenbutton"
          >Submit Certificate</button>
         
        </div>
        
      }




</div>
    </div>
  );
}

export default Finalcertificate;
