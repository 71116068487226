import React, { useContext, useState,useEffect } from 'react';
import axios from 'axios';
import Master from './Master';
import './Courseconfiguration.css';

const Courseconfigurationpgmdms = () => {
  const {
    roleid,baseurl,isloading,setisloading
 } = useContext(Master);
  const [tabledata, settabledata] = useState([]);
  const [addedRows, setaddedRows] = useState([]);
  const [modifiedRows, setmodifiedRows] = useState([]);

  // Handle changes in table cells (both for added and modified rows)
  const handleinputchange = (index, field, value) => {
    const updatedData = [...tabledata];
    updatedData[index][field] = field === 'active' ? value.target.checked : value.target.value;
    settabledata(updatedData);

    const row = updatedData[index];
    if (!row.id) {
      // If id is not available (newly added row)
      if (!addedRows.some(item => item === row)) {
        setaddedRows([...addedRows, row]);
      }
    } else {
      // If id exists (modified row)
      const updatedModifiedRows = modifiedRows.map(item =>
        item.id === row.id ? row : item
      );
      if (!modifiedRows.some(item => item.id === row.id)) {
        setmodifiedRows([...modifiedRows, row]);
      } else {
        setmodifiedRows(updatedModifiedRows);
      }
    }
  };

  // Add a new row with empty fields
  const handleaddrow = () => {
    const newRow = {program:'PG', category: 'MD/MS', course: '', intake: '', price: '', active: false };
    settabledata([...tabledata, newRow]);
  };

  // Send both added and modified rows to the backend
  const handlepostdata = async () => {
    try {
      const dataToSend = {
        added: addedRows,
        modified: modifiedRows
      };
      setisloading(true);


      axios({
        url: baseurl+"/updatecourse",
        method: "POST",
        headers: {
             authorization: `Bearer ${localStorage.getItem("token")}`  ,
        },
        data: {courses:dataToSend},
        
    }).then((response) => {

      if(response.data.success){
        // settabledata(response.data.data)

        var result = response.data.data.filter(obj => {
          return obj.category === "MD/MS"
        })
        settabledata(result)

        setaddedRows([]);
        setmodifiedRows([]);
      }else{

      }
  })
  .catch((error) => {

      
   
  });




      // await axios.post('https://example.com/api/save-data', dataToSend);
      // alert('Data saved successfully!');
      // // Reset arrays after posting
      // setaddedRows([]);
      // setmodifiedRows([]);



    } catch (error) {
      console.error('Error saving data:', error);
    }
    finally{
      setisloading(false);
    }
  };


  

  useEffect (() => {
setisloading(true);
    axios({
      url: baseurl+"/pgcoursemdms",
      method: "GET",
      headers: {
           authorization: `Bearer ${localStorage.getItem("token")}`  ,
      },
      data: {},
      
  }).then((response) => {
    if(response.data.success){
      // var result = response.data.data.filter(obj => {
      //   return obj.category === "MD/MS"
      // })
      settabledata(response.data.data)

      // settabledata(response.data.data)
      setaddedRows([]);
      setmodifiedRows([]);
    }else{

    }
})
.catch((error) => {

    
 
})
.finally(()=>{
  setisloading(false);
});


  
  }, []);





  

  return (
    <div className="datatable-container">
       <h3>PG MD/MS Courses</h3>
      <table className="datatable">
        <thead>
          <tr>
            {/* <th>category</th> */}
            <th>course</th>
            <th>intake</th>
            <th>price</th>
            <th>active</th>
          </tr>
        </thead>
        <tbody>
          {tabledata.map((row, index) => (
            <tr key={index}>
              {/* <td>
                <input
                  type="text"
                  value={row.category}
                  onChange={(e) => handleinputchange(index, 'category', e)}
                />
              </td> */}
              <td>
                <input
                  type="text"
                  value={row.course}
                  onChange={(e) => handleinputchange(index, 'course', e)}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.intake}
                  onChange={(e) => handleinputchange(index, 'intake', e)}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.price}
                  onChange={(e) => handleinputchange(index, 'price', e)}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={row.active}
                  onChange={(e) => handleinputchange(index, 'active', e)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div><button onClick={handleaddrow}>Add Row</button><button onClick={handlepostdata}>Save Changes</button></div>
          <br/><br/>
    <div> </div> 
    {isloading && (
       <div className="loading-overlay">
       <div className="spinner"></div>
     </div>
    )}
    </div>
  );
};

export default Courseconfigurationpgmdms;
