
import React, { useContext, useState } from 'react';
import styles from './Footer.module.css';
import Maincontent from './Maincontent';
import UserLogin from './UserLogin';
import Master from './Master';
import RegistrationForm from './RegistrationForm';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CollegeProfile from './CollegeProfile';
import Configuration from './Configuration';
import Login from './UserLogin';
import Header from './Header'
import './Layout.css';
import Profile from './Profile';
import AcademicYearInput from './AcademicYearInput';
import Sidebar from './Sidebar';
import Courseconfigurationug from './Courseconfigurationug';
import Courseconfigurationpgmdms from './Courseconfigurationpgmdms';
import Courseconfigurationpgmds from './Courseconfigurationpgmds';
import Collegetableug from './Collegetableug';
import Collegetablepgmdms from './Collegetablepgmdms';
import Collegetablepgmds from './Collegetablepgmds';
import Programselector from './Programselector';
import Affiliationoptions from './Affiliationoptions';
import UploadCollegeFiles from './UploadCollegeFiles';
import Assessorreport from './Assessorreport';
import SelectAssessor from './SelectAssessor';
import Finalcertificate from './Finalcertificate';
import Paymentassessor from './Paymentassessor';

import BigFileUpload from './BigFileUpload';
import ExcelUpload from './excelfileupload';
import Workflow from './Workflow';
const Layout = () => {

  const {
    roleid,selectedprogram, setselectedprogram

 } = useContext(Master); 

  return (
    <div className="layout">

      <header className="layout-header">
        <Header/>
      </header>
      <div className="layout-body">
        <aside className="layout-sidebar">
       
          <Sidebar/>
        </aside>
        <main className="layout-content">
        <Routes>
        <Route path="/*" element={<Workflow/>} />

        </Routes>
        </main>
      </div>

      <footer className="layout-footer">Footer


      </footer>
      
    </div>
  );
};

export default Layout;

//   );
// }

// export default Layout;