

import React, { useContext, useState, useEffect } from "react";
// import { assessorsData } from './dropDownOptions'; // Adjust the import path
import axios from "axios";
import Master from "./Master";
import './SelectAssessor.css';

const SelectAssessor = () => {
  const [selectedAssessor, setSelectedAssessor] = useState(null);
  const [selectedAssessoremail, setSelectedAssessoremail] = useState(null);
  const [assessorsdata,setassessorsdata]=useState([])
  const { baseurl, collegrequestedocs, setcollegrequestedocs, roleid,selectedrequestobject
    ,setselectedrequestobject
  } = useContext(Master);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disablassignassessorbutton,setdisablassignassessorbutton]=useState(true);
  const [displaysuccessmessage,setdisplaysuccessmessage]=useState("");


  const handleRowSelect = (id,email) => {
    setSelectedAssessor(id); // Set the selected assessor; deselect others
    setSelectedAssessoremail(email);
    setdisablassignassessorbutton(false);
  };


  function handlemassignaccessor() {



    axios({
      url: baseurl+"/assignassessortask",
      method: "POST",
      headers: {
          authorization: `Bearer ${localStorage.getItem("token")}` ,
      },
   
        data: {
          affiliationrequestid:selectedrequestobject.requestid,
          assessoremail:selectedAssessoremail
  }
  
  
      
  }
  )
      .then((response) => {         
          if(response.data.success){
            setSuccessMessage(true);
            setErrorMessage(false);
           setdisplaysuccessmessage("Assessor assigned successfully");
            
            setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorreport: true }))
            setdisablassignassessorbutton(true);
  
          }
  
      })
      .catch((error) => {
        setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorreport: true }))
        setSuccessMessage(false);
        setErrorMessage(true);
        setdisplaysuccessmessage("Unexpected Error");
        setdisablassignassessorbutton(false);

      });
  }

  


  useEffect(() => {



    axios({
      url: baseurl + "/getassessorreport",
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },

      data: {
        affiliationrequestid: selectedrequestobject.requestid,
      }

    }
    )
      .then((response) => {
        if (response.data.success) {


          if (response.data.data[0].status == 1) {
        

            setselectedrequestobject((prevdata) => ({ ...prevdata, showaffiliationpayment: true }))
          } else {
            setselectedrequestobject((prevdata) => ({ ...prevdata, showaffiliationpayment: false }))
          }

        } else {

          setselectedrequestobject((prevdata) => ({ ...prevdata, showaffiliationpayment: false }))

        }

      })
      .catch((error) => {

      });


  },[])


    useEffect(() => {
    const fetchAssessors = async () => {
      try {
        // 
        const response = await axios.get(baseurl+'/getassessorsdetails');
        setassessorsdata(response.data.assessors); // Save fetched assessors in state
        
      } catch (error) {
        console.error('Error fetching assessors:', error);
      }
    };

    fetchAssessors();

  }, []); // Empty array ensures it runs only once when the component mounts




useEffect(() => {

  axios({
    url: baseurl + "/getassignedassessor",
    method: "POST",
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },

    data: {
      affiliationrequestid: selectedrequestobject.requestid,
    }

  }
  )
    .then((response) => {


      if (response.data.success) {
      

        if (response.data.data.length == 1) {
      
       // important

          setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorreport: true }))
          // setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorselection: true }))
          
          
        } 
        
        if (response.data.data.length == 0)  {
          setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorreport: false }))
          // setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorselection: true }))
        }


        if(response.data.data.length >1) {
          setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorreport: false }))
          setErrorMessage(true);
          setdisplaysuccessmessage("More than one assessor assigned to this request, Please Contact admin");
        }

      } 
      

      
      else {

        setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorreport: false }))
        setselectedrequestobject((prevdata) => ({ ...prevdata, showassessorselection: false }))

      }

    })
    .catch((error) => {

    });


},[])
  

  return (

    <>
  {roleid==process.env.REACT_APP_ADMIN && <>
    <div className="selectaccessor">
      
      <h3>Select Assessor</h3>
      <table border="1">
        <thead>
          <tr>
            <th>Select</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Years of Experience</th>
            <th>College Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {assessorsdata.map((assessor) => (
            <tr key={assessor.id}>
              <td>
                <input
                  type="checkbox" 
                  name="assessorSelection" 
                  checked={selectedAssessor === assessor.id}
                  onChange={() => handleRowSelect(assessor.id,assessor.email)}
                />
              </td>
              <td>{assessor.name}</td>
              <td>{assessor.designation}</td>
              <td>{assessor.yearsofexperience}</td>
              <td>{assessor.collegename}</td>
            <td>{assessor.email}</td>
            </tr>
          ))}
        </tbody>
      </table>


      {/* {selectedAssessor && (
        <div>
          <h4>Selected Assessor:</h4>
          <p>
            ID: {selectedAssessor} - Name: {
              assessors.find((assessor) => assessor.id === selectedAssessor)?.name
            }
          </p>
        </div>
      )} */}

        <br/> <br/>
{(roleid == process.env.REACT_APP_ADMIN  && ((selectedrequestobject).showassessorreport)  /* &&  selectedrequestobject.status==0 */) &&
        <div >
          <button className="greenbutton"  onClick={handlemassignaccessor}
             disabled={disablassignassessorbutton}
            // className="uploadfilesumitbtn"
          >Assign Assessor</button>
        </div>
      }

          {successMessage && (
    <p className="successmessage">{displaysuccessmessage}</p>
)}
{errorMessage&&(
  <p className="errormessage">{displaysuccessmessage}</p>
)}
      
    </div>


    </>
}
    </>
  );
};

export default SelectAssessor;

/////////////////////////////////////////////////////////////


