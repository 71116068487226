import React, { useContext, useState, useEffect } from "react";
import Textarea from "./elements/textarea/Textarea";
import './UploadCollegeFiles.css';
import axios from "axios";
import Master from "./Master";
import { affiliationtype } from "./dropDownOptions";
import { data } from "react-router-dom";

function UploadCollegeFiles() {
  const { baseurl, collegrequestedocs, setcollegrequestedocs, roleid,
    newaffiliationrequest, setnewaffiliationrequest,
    affiliationrequestid, setaffiliationrequestid, affiliationtypeid,
    userid, academicyearid, affiliationrequestsubmissioncompletion,
    setaffiliationrequestsubmissioncompletion,
    updateshowformsubmissionbutton, showformsubmissionbutton, selectedrequestobject
    , setselectedrequestobject, isloading, setisloading
  } = useContext(Master);


  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disablemovetonextstepbutton, setdisablemovetonextstepbutton] = useState(true);
  // const [displaysuccessmessage,setdisplaysuccessmessage]=useState("");
  const handlefilechange = async (e, fileid, index) => {

    const updateddata = [...collegrequestedocs];
    updateddata[index].file = e.target.files[0];
    updateddata[index].fileselected = true;
    updateddata[index].uploadstatus = "File Selected but not uploaded";
    updateddata[index].approvalstatus = "Action Pending";
    updateddata[index].comment = "";
    setcollegrequestedocs(updateddata);


  };
  const handletextareachange = (index, comment) => {
    // setformdata((prevData) => ({ ...prevData, [name]: value }));

    const updateddata = [...collegrequestedocs];
    updateddata[index].rejectedcomment = comment;
    setcollegrequestedocs(updateddata);


  };

  const handleradiochange = (index, value) => {


    const updateddata = [...collegrequestedocs];
    updateddata[index].documentapprovalstatus = value;

    setcollegrequestedocs(updateddata);

  };




  const handlemovetonextstep = async (status) => {
    // Prepare your form data or any necessary data for the request
    setisloading(true);
    axios({
      url: baseurl + '/updaterequeststatus',
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {

        "affiliationrequestid": Object.keys(selectedrequestobject) == 0 ? affiliationrequestid : selectedrequestobject.requestid,
        "userid": parseInt(userid),
        "status": status,
      }

    })
      .then((response) => {

        setisloading(false);
        if (response.data.success) {
          // alert("Request status updated successfully");
          setSuccessMessage(`Request ID ${response.data.body.affiliationrequestid} has been Approved for Next step!`);
          // setErrorMessage(false)
          //  setshowrequestsubmit(false);
          // setdisplaysuccessmessage(`Request ID ${response.data.affiliationrequestid} has been Approved for Next step!`)
          setselectedrequestobject((prevData) => ({ ...prevData, ['status']: 1 }));

        }
        else {
          //throw new Error("Failed to update request status");
          // setErrorMessage(response.data.message);
          // alert("Expected error")
          // setSuccessMessage(false);
          setErrorMessage(response.data.message);
          // setdisplaysuccessmessage(`Unexpected error occurred!`);
        }
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error updating request status:", error.response?.data || error.message);
        // setSuccessMessage("Error occurred during submission. Please try again.");
        //setTimeout(() => setSuccessMessage(""), 5000); // Clear the message after 5 seconds
        // setSuccessMessage(false);
        setErrorMessage(error.response.message)
        // setdisplaysuccessmessage(`Unexpected error occurred!`);

      });
  };








  useEffect(() => {
    setaffiliationrequestsubmissioncompletion((prevData) => ({ ...prevData, ['documentssubmissionscomplete']: false }))
    setisloading(true);

    axios({
      url: baseurl + '/requestdoc',
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        affiliationrequestid: Object.keys(selectedrequestobject).length > 0 ? selectedrequestobject.requestid : null,
        affiliationtypeid: affiliationtypeid

      }


    }).then((response) => {




      if (response.data.success) {


        var responsedata = response.data.data;
        responsedata.forEach(function (element) {
          element.file = null
          element.fileselected = false;
          element.filechanged = false;
          element.fileuploaded = false;
          element.uploadsuccess = false;
          element.uploadstatus = " File not selected";
          element.documentactionstatus = "";
          // element.approvalstatus = "Action Pending";
          element.comment = "";
          element.rejectedcomment = "";
          element.uploadeddocumentstatus = "Action Pending";


          //////////////////////////////////////////////////////

          // file
          // fileselected
          // filechanged
          // fileuploaded
          // fileuploadedsuccess
          // fileuploadedstatus

          //////////////////////////////////////////////////////

          // approvalselectedoption
          // approvalselectedoptionuploaded
          // approvalselectedoptionuploadedstatus
          // approvalselectedoptionuploadedsuccess
          // rejectedcomment


        });


        setcollegrequestedocs(responsedata)
      }
    })
      .catch((error) => {
        // alert("Unexpected Error!")


      })
      .finally(() => {
        setisloading(false);
      })

  }, []);


  //   

  function handledownloadsubmittedfile(fileid, index) {
    // Find the file object from the collegrequestedocs state using fileid
    const fileDetails = collegrequestedocs.find(doc => doc.id === fileid);

    // Fallback filename if fileDetails is not found
    let fileName = "downloaded_file";

    // Assign file_name if fileDetails is found
    if (fileDetails && fileDetails.file_name) {
      fileName = fileDetails.file_name;
    }

    axios({
      url: baseurl + '/filedownload',
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: 'blob', // Handle binary data
      data: {
        fileid: fileid,
        affiliationrequestid: selectedrequestobject.requestid,
      },
    })
      .then((response) => {
        // Create a blob from the response data
        const blob = new Blob([response.data], { type: response.headers["content-type"] });

        // Create a link element to trigger file download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName; // Use the file name from collegrequestedocs
        document.body.appendChild(link);
        link.click();
        link.remove(); // Clean up the link element
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        alert("Failed to download file. Please try again.");
      });
  }



  function handlefileapprovalstatus(index) {


    const updateddata = [...collegrequestedocs];
    updateddata[index].documentactionstatus = "updating.......";
    setcollegrequestedocs(updateddata);


    axios({
      url: baseurl + '/updatefilestatus',
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {

        "affiliationrequestid": selectedrequestobject.requestid,
        "userid": parseInt(userid),
        "fileid": collegrequestedocs[index].id,
        "status": parseInt(collegrequestedocs[index].documentapprovalstatus),
        "comment": collegrequestedocs[index].rejectedcomment,
      }

    })



      .then((response) => {


        if (response.data.success) {
          // Display success message with request ID
          // setSuccessMessage(`Request ID ${response.data.affiliationrequestid} has been successfully submitted!`);
          //setTimeout(() => setSuccessMessage(""), 5000); // Clear the message after 5 seconds
          // setSuccessMessage(true);
          // setshowrequestsubmit(false);
          const updateddata = [...collegrequestedocs];
          if (updateddata[index].documentapprovalstatus == 1) {
            updateddata[index].documentactionstatus = "Approved";
          } else {
            updateddata[index].documentactionstatus = "Email Sent for more information";
          }

          setcollegrequestedocs(updateddata);
        }
        else {
          const updateddata = [...collegrequestedocs];
          updateddata[index].documentactionstatus = "Unexpected Error/ please try again";
          setcollegrequestedocs(updateddata);
          //throw new Error("Failed to update request status");

        }

        checkmovetonextstepbutton();
      })
      .catch((error) => {
        console.error("Error updating request status:", error.response?.data || error.message);

        //setTimeout(() => setSuccessMessage(""), 5000); // Clear the message after 5 seconds
      });



  }




  //   function handlesubmitaffiliationrequest(status) {


  // ////////////////////////////////////////////////////////////////////


  //    // Prepare your form data or any necessary data for the request

  //    // Send the request
  //      axios({
  //       url: baseurl + '/updaterequeststatus',
  //       method: "POST",
  //       headers: {
  //         authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //       data:{

  //         "affiliationrequestid":affiliationrequestid,
  //         "userid":parseInt(userid),
  //        "status":0,
  //       }

  //     })


  //      .then((response) => {
  //        

  //        if (response.data.success) {
  //          // Display success message with request ID
  //         // setSuccessMessage(`Request ID ${response.data.affiliationrequestid} has been successfully submitted!`);
  //          //setTimeout(() => setSuccessMessage(""), 5000); // Clear the message after 5 seconds
  //          setSuccessMessage(true);
  //          setErrorMessage(false)
  //         //  setshowrequestsubmit(false);
  //         setdisplaysuccessmessage(`Request ID ${response.data.affiliationrequestid} has been successfully submitted!`)
  //         setselectedrequestobject((prevData) => ({ ...prevData, ['status']: 1 }));
  //        } 
  //        else {
  //          //throw new Error("Failed to update request status");
  //         //  setErrorMessage(response.data.message);
  //          setSuccessMessage(true);
  //          setErrorMessage(false)
  //          setdisplaysuccessmessage("Error occurred during submission!")
  //        }
  //      })
  //      .catch((error) => {
  //        console.error("Error updating request status:", error.response?.data || error.message);
  //       //  setSuccessMessage("Error occurred during submission. Please try again.");
  //        //setTimeout(() => setSuccessMessage(""), 5000); // Clear the message after 5 seconds
  //                //  setErrorMessage(response.data.message);
  //                setSuccessMessage(true);
  //                setErrorMessage(false)
  //                setdisplaysuccessmessage("Error occurred during submission!")
  //      });

  //      ///////////////////////////////////////////////////////




  //   }


  async function handleuploadselectedfile(id, index) {


    ///////////////////////////////////////////////////

    const formData = new FormData();
    formData.append("file", collegrequestedocs[index].file);
    formData.append("fileid", collegrequestedocs[index].id);
    formData.append("affiliationtypeid", affiliationtypeid);
    formData.append("newaffiliationrequest", newaffiliationrequest);
    formData.append("affiliationrequestid", affiliationrequestid);
    formData.append("userid", userid);
    formData.append("academicyearid", academicyearid);
    formData.append("roleid", roleid);
    setisloading(true);
    // userid,academicyearid
    try {

      // setUploadStatus("Uploading...");
      const updateddata = [...collegrequestedocs];
      updateddata[index].uploadstatus = "Uploading...";
      setcollegrequestedocs(updateddata);


      const res = await axios.post(baseurl + '/fileupload', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setisloading(false);

      if (res.data.success) {
        setaffiliationrequestid(res.data.data.requestid);

        const updateddata = [...collegrequestedocs];
        updateddata[index].uploadstatus = "Upload Success";
        updateddata[index].fileuploaded = true;
        updateddata[index].uploadsuccess = true;
        setcollegrequestedocs(updateddata);



      }
      // setUploadStatus("File uploaded successfully!");

    } catch (error) {
      setisloading(false);
      const updateddata = [...collegrequestedocs];
      updateddata[index].uploadstatus = "Error in file upload";
      updateddata[index].fileuploaded = true;
      updateddata[index].uploadsuccess = false;
      setcollegrequestedocs(updateddata);
      setTimeout(() => setSuccessMessage(""), 5000);


      console.error("File upload error:", error.response?.data || error.message);
      // setUploadStatus("File upload failed.");
    }
    setisloading(false);
    var documentssubmissionscomplete = checkifallFilesUploaded();
    setaffiliationrequestsubmissioncompletion((prevData) => ({ ...prevData, ['documentssubmissionscomplete']: documentssubmissionscomplete }))

  }



  const checkifallFilesUploaded = () => {

    var showsubmitbutton = true;

    for (let index = 0; index < collegrequestedocs.length; index++) {
      const element = collegrequestedocs[index];
      if (element.uploadsuccess == false) {
        showsubmitbutton = false;
      }
    }

    // setshowrequestsubmit(showsubmitbutton);
    return showsubmitbutton;
  };


  function checkmovetonextstepbutton() {



    var disable = false;
    for (let index = 0; index < collegrequestedocs.length; index++) {
      const element = collegrequestedocs[index];
      if ((element.documentactionstatus !== 'Approved') || (element.hasOwnProperty('documentactionstatus') == false)) {


        disable = true;
      }
    }
    setdisablemovetonextstepbutton(disable);

  }





  useEffect(() => {

    updateshowformsubmissionbutton();

  }, [affiliationrequestsubmissioncompletion]);


console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>          ", selectedrequestobject);



  return (
    <div className="fileuploadwrapper">
      <div className="fileUploads">
        {/* File Upload Sections */}
        {
          collegrequestedocs.map(({ documentactionstatus, documentapprovalstatus, rejectedcomment, uploadstatus, approvalstatus, id, name, haslink, link, comment, status, accept = ".pdf,.docx,.doc" }, index) => (

            ///////////////////////////////////////////////////////////////

            <div key={name} className="file-upload">
              <h3>{name}</h3>
              <div className="downloadbuttons">
                {haslink == 1 && roleid == process.env.REACT_APP_COLLEGE && <>
                  <a href={link} target="_blank" download>
                    <button className="templatedownload">Download Template</button>
                  </a>
                </>
                }


              </div>

              {/* /////////////////////////////////////////////////////////////// */}


              {/*///////////////////////////////////////////////////////////  */}

              <br />
              {(roleid == process.env.REACT_APP_COLLEGE) &&

                (selectedrequestobject.status == process.env.REACT_APP_CORRECTION || Object.keys(selectedrequestobject).length == 0)


                &&
                <input type="file" accept={accept} onChange={(e) => handlefilechange(e, id, index)} />
              }

              {/* //////////////////////////////////////////////////////////// */}
              <br />

              {(roleid == process.env.REACT_APP_COLLEGE) &&

                (selectedrequestobject.status == process.env.REACT_APP_CORRECTION || Object.keys(selectedrequestobject).length == 0)


                && <>
                  <div className="downloadbuttons">
                    <button className="templatedownload" onClick={() => handleuploadselectedfile(id, index)}>Upload selected File</button>
                  </div>
                  <span> Status: {uploadstatus} </span>
                </>
              }
              {/* ///////////////////////////////////////////////////////////////////// */}

              {/* ////////////////////////////////////////////////////////////////////////////// */}

              <div className="downloadbuttons">
                {

                  (Object.keys(selectedrequestobject).length > 0) &&
                  <>
                    <button className="templatedownload" onClick={() => handledownloadsubmittedfile(id, index)}>Download Submitted File</button>
                  </>
                }


              </div>



              {/* /////////////////////////////////////////////////////////////////// */}

              {((roleid == process.env.REACT_APP_ADMIN && 
                Object.keys(selectedrequestobject).length > 0)

                &&

                (selectedrequestobject.status == process.env.REACT_APP_SUBMITTED || selectedrequestobject.status == process.env.REACT_APP_CORRECTION))
                &&
                <div className="formradioinput">
                  <input type="radio" name={name} value="1" checked={documentapprovalstatus === "1"} onChange={(e) => handleradiochange(index, e.target.value)} />
                  <label>Approved</label>
                  <input type="radio" name={name} value="0" checked={documentapprovalstatus === "0"} onChange={(e) => handleradiochange(index, e.target.value)} />
                  <label>Request more information</label>
                </div>
              }
              {documentapprovalstatus == "0" && (
                <Textarea
                  placeholder={`Add comments for ${name}`}
                  name={name}
                  value={rejectedcomment}
                  update={(value) => handletextareachange(index, value)}
                />
              )}
              <div className="downloadbuttons">
                {((documentapprovalstatus === "1") || (documentapprovalstatus === "0")) && <>
                  <button className={documentapprovalstatus === "1" ? "greenbutton" : "orangebutton"} onClick={() => handlefileapprovalstatus(index)}>{documentapprovalstatus === "1" ? "Confirm Approval" : "Send Reupload Email"}</button><br />
                  <span> Status: {documentactionstatus == "" ? "Action Pending" : documentactionstatus} </span>
                </>

                }
              </div>


            </div>
          ))}

      </div>

      <div className="Confirmandsubmit">


        {(roleid == process.env.REACT_APP_COLLEGE && (Object.keys(selectedrequestobject) == 0)) &&
          <div >
            <button className="greenbutton" onClick={() => handlemovetonextstep(process.env.REACT_APP_SUBMITTED)}
              disabled={!showformsubmissionbutton}
            // className="uploadfilesumitbtn"
            >Submit Affiliation Request</button>

          </div>
        }


        {(roleid == process.env.REACT_APP_ADMIN && (Object.keys(selectedrequestobject).length > 0) && selectedrequestobject.status == 0) &&
          <div >
            <button className="greenbutton" onClick={() => handlemovetonextstep(process.env.REACT_APP_CORRECTION)}
              //  disabled={!allFilesUploaded()}
              disabled={disablemovetonextstepbutton}
            // className="uploadfilesumitbtn"
            >Request More Information</button>
          </div>
        }



        {(roleid == process.env.REACT_APP_ADMIN && (Object.keys(selectedrequestobject).length > 0) && selectedrequestobject.status == 0) &&
          <div >
            <button className="greenbutton" onClick={() => handlemovetonextstep(process.env.REACT_APP_ACCEPTED)}
              //  disabled={!allFilesUploaded()}
              disabled={disablemovetonextstepbutton}
            // className="uploadfilesumitbtn"
            >Move To Next Step</button>
          </div>
        }




        {(roleid == process.env.REACT_APP_COLLEGE && (Object.keys(selectedrequestobject).length > 0) && selectedrequestobject.status == process.env.REACT_APP_CORRECTION) &&
          <div >
            <button className="greenbutton" onClick={() => handlemovetonextstep(process.env.REACT_APP_RESUBMISSION)}
              disabled={!showformsubmissionbutton}
            // className="uploadfilesumitbtn"
            >Submit With Corrections</button>

          </div>
        }





      </div>

      {successMessage && (
        <p className="successmessage">{successMessage}</p>
      )}
      {errorMessage && (
        <p className="errormessage">{errorMessage}</p>
      )}



    </div>
  );
}

export default UploadCollegeFiles;
