import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
// import './App.scss';
import RegistrationForm from './RegistrationForm';
import UserLogin from './UserLogin';
import { useState } from 'react';
import Layout from './Layout';
import Homepage from './Homepage';
  
function App() {


  return (
    <div className="App">
        <Routes>
          <Route path="/*" element={<Homepage />} />
        </Routes>    
    </div>
  );
}

export default App;
