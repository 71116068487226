

import React, { useState, useEffect,useContext } from 'react';
import Master from './Master';
import axios from 'axios';
import './programselector.css';

const ProgramSelector = () => {
  const [localselectedprogram, setlocalselectedprogram] = useState('');
  const [localpgsuboptions, setlocalpgsuboptions] = useState({
    md_ms: false,
    mds: false,
  });
  
      const {
        baseurl,selectedrequestobject,setselectedrequestobject,
        updateselectedprogram,updatepgsuboptions,setselectedprogram, isloading, setisloading,affiliationrequestid
     } = useContext(Master);

  const handleradiochange = (event) => {
    updateselectedprogram(event.target.value);
    setlocalselectedprogram(event.target.value);
    // Reset PG suboptions if switching to UG
    if (event.target.value !== 'PG') {
      updatepgsuboptions({ md_ms: false, mds: false });
      setlocalpgsuboptions({ md_ms: false, mds: false });
    }
  };

  const handlecheckboxchange = (event) => {
    const { name, checked } = event.target;


    updatepgsuboptions(name, checked);

    setlocalpgsuboptions((prev) => ({
      ...prev,
      [name]: checked,
    }));

  };



  useEffect(() => {
    setisloading(true);
    console.log(selectedrequestobject.requestid,"done this time.............................");
    if (selectedrequestobject.requestid != 'undefined' && typeof selectedrequestobject.program == 'undefined' ) {
      /////////////////////////////////////////////////////////////////
      axios({
        url: baseurl + "/getrequestedcourses",
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {requestid:selectedrequestobject.requestid},

      }).then((response) => {
        if (response.data.success) {

          if(response.data.data[0].program =="UG"){
            setlocalselectedprogram('UG');
            setselectedprogram('UG');
            setselectedrequestobject((prev) => ({
              ...prev,
              program: 'UG',
            }))
          }
          if(response.data.data[0].program =="PG"){
            setlocalselectedprogram('PG')
            setselectedprogram('PG');
            setselectedrequestobject((prev) => ({
              ...prev,
              program: 'PG',
            }))
          }

        } 
        
        else {

        }
      })
        .catch((error) => {

         // alert("Unexpexted error occured. Please try again later.");
        })
        .finally(() => {
          setisloading(false);
        })
      ///////////////////////////////////////////////////////////////////


    }

    return () => {
        // Anything in here is fired on component unmount.
    }


}, [])






  return (
    <div className="programselector-container">
      <h3 className="program-label">Select Program:</h3>
      <div className="radio-group">
        <label>
          <input
            type="radio"
            name="program"
            value="UG"
            checked={localselectedprogram === 'UG'}
            onChange={handleradiochange}
             disabled={Object.keys(selectedrequestobject).length>0}
          />
          UG
        </label>
        <label>
          <input
            type="radio"
            name="program"
            value="PG"
            checked={localselectedprogram === 'PG'}
            onChange={handleradiochange}
            disabled={Object.keys(selectedrequestobject).length>0 || affiliationrequestid != null}
          />
          PG
        </label>
      </div>

      {/* <p className="selected-program">
        Selected Program: <strong>{selectedprogram || 'None'}</strong>
      </p> */}

      {localselectedprogram === 'PG' && (
        <div className="pg-suboptions">
           <label className="course-type-label">Select Course Type:   &nbsp;  &nbsp; </label>
          <label>
            <input
              type="checkbox"
              name="md_ms"
              checked={localpgsuboptions.md_ms}
              onChange={handlecheckboxchange}
              disabled={Object.keys(selectedrequestobject).length>0}
            />
            MD/MS
          </label>
          <label>
          &nbsp;  &nbsp;&nbsp;  &nbsp;
            <input
              type="checkbox"
              name="mds"
              checked={localpgsuboptions.mds}
              onChange={handlecheckboxchange}
              disabled={Object.keys(selectedrequestobject).length>0}
            />
            MDS
          </label>
        </div>
      )}
      {isloading && (
  <div className="loading-overlay">
    <div className="spinner"></div>
  </div>
)}
    </div>
  );
};

export default ProgramSelector;
