import React, { useContext, useState, useEffect } from 'react';
import Master from './Master';
import axios from 'axios';
import './AcademicYearInput.css';

const AcademicYearInput = () => {
  const {
    academicid,
    setacademicid,
    iscurrent,
    setiscurrent,
    baseurl,
    academicyearid, setacademicyearid,
    academicyear, setacademicyear,
    roleid,userid,isloading,setisloading
  } = useContext(Master);

  useEffect(() => {
    // Fetch the current academic year on component mount
    setisloading(true);
    axios({
      url: baseurl+'/currentacademicyear',
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      // data: {userid:userid,roleid:roleid},
    })
      .then((response) => {
        if (response.data.success) {
          const { start, end } = response.data.data;
          const formattedYear = `${start+""}-${(end+"").substring(2,4)}`;
          setacademicyear(formattedYear);
          setacademicyearid(response.data.data.id);          
          setiscurrent(response.data.data.iscurrent === 1);
        }
      })
      .catch((error) => {
        console.error('Error fetching current academic year:', error);
      })
      .finally(() => {
        setisloading(false);
      });
  }, []);

  const handleacademicyearChange = (e) => {
    const value = e.target.value;
    const regex = /^\d{4}-\d{2}$/;

    if (regex.test(value)) {
      const [startYear, endYear] = value.split('-').map(Number);
      var currentendyear = (startYear+1)
      currentendyear = parseInt((currentendyear+"").substring(2,4)+"");
      
      if (endYear === currentendyear) {
        setacademicyear(value);
      } else {
        console.error('Invalid academic year format');
      }
    } else {
      setacademicyear(value);
    }
  };

  const handleCheckboxChange = () => {
    const newStatus = !iscurrent;
    setiscurrent(newStatus);
    setisloading(true);

    // Update the academic year status in the backend
    axios({
      url: baseurl + '/updateacademicyear',
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: {
        id: academicid,
        start: academicyear.split('-')[0],
        end: `${academicyear.split('-')[0].slice(0, 2)}${academicyear.split('-')[1]}`,
        iscurrent: newStatus ? 1 : 0,
      },
    })
      .then((response) => {
        if (response.data.success) {
        }
      })
      .catch((error) => {
      })
      .finally(()=> {
        setisloading(false);
      });
  };


  return (
    <div className="academic-year-input">
      {roleid ==process.env.REACT_APP_ADMIN &&
        <>
          <div className="field">
            <label>Academic Year:</label>
            
            <input
              type="text"
              value={academicyear}
              onChange={handleacademicyearChange}
              placeholder="e.g., 2024-25"
              maxLength="7"
              onBlur={handleCheckboxChange}
              onMouseOut={handleCheckboxChange}
            />
          </div>
          {/* <div className="field">
            <input
              type="checkbox"
              checked={iscurrent}
              onChange={handleCheckboxChange}
            />
            <label>Current Academic Year:</label>
          </div> */}
        </>
}


{
((roleid ==process.env.REACT_APP_ASSESSOR ) || (roleid ==process.env.REACT_APP_COLLEGE))&&
        <span>Academic Year: {academicyear || '—'}</span>
   
}

{isloading && (
  <div className="loading-overlay">
    <div className="spinner"></div>
  </div>
)}
</div>
  );
};

export default AcademicYearInput;