import React, { useContext, useState } from 'react';
import styles from './Footer.module.css';
import Layout from './Layout';
// import Maincontent from './Maincontent';
import UserLogin from './UserLogin';
import Master from './Master';
import RegistrationForm from './RegistrationForm';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './UserLogin';
// import FileUpload from './FileUpload';
import Profile from './Profile';
import UploadCollegeFiles from './UploadCollegeFiles';
//import CollegeUploads from './collegeuploads';
import Workflow from './CollegeProfile';
function Homepage() {

  //const { loginData, updateLoginData, theRoute, setTheRoute } = useContext(Master);

  return (
    
    <Layout>
      <Routes>
      <Route path="/*" element={<Workflow />} />
        {/* <Route path="/register" element={<RegistrationForm />} /> */}
        {/* <Route path="/login" element={<UserLogin />} /> */}
        {/* <Route path="/home" element={<Maincontent />} /> */}
        {/* <Route path="/profile" element={<Profile />} /> */}
        {/* <Route path="/fileuploads" element={<CollegeUploads />} /> */}
        {/* <Route path="/uploads" element={<UploadCollegeFiles />} /> */}

         {/* <Route path="/configuration" element={<ConfigurationAdmin />} /> 
         <Route path='/request' element={<CollegeRequest/>} /> */}
      </Routes>
   </Layout>
   

  );
}

export default Homepage;