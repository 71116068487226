import React, { useState } from 'react';
import Master from "../../Master";

const Dropdown = ({
  options = [],
  labelField,
  valueField ,
  className ,
  onChange,
  values,
  placeholder,
  name

}) => {


  let [selectedvalue, setselectedvalue] = useState("");

  const isPlaceholderSelected = !selectedvalue;
  return (
    <div className={`dropdown-container ${className}`}>
      <select
        value={selectedvalue || ""}
        onChange={(e)=>{onChange(name,e.target.value) ; setselectedvalue(e.target.value)}}
        className={`custom-select ${isPlaceholderSelected ? "placeholder" : ""}`}
        style={{
          padding: "8px",
          fontSize: "16px",
          borderRadius: "4px",
          border: "1px solid #ccc",
          width: "418px",
          height: "44px",
        }}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option[valueField]}>
            {option[labelField]}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
