import React, { useState } from 'react';
import './text.css';



const Text = (props) => {
    let [haserror, sethaserror] = useState(false);
    let [inedit, setinedit] = useState(false);
    let [ipboxclass,setipboxclass]=useState("ipbox");

    function handlefocus(e) {
        // sethaserror(false);
        setipboxclass('ipboxedit');
    }
    function handlefocusout(e) {
        validation(e,'focusout');
    }

   function handleMouseEvent(e){
    validation(e,'focusout');
   }

    function handlechange(e) {
        props.update(e)
        if(props.regx!=undefined || props.regex!=null){
            validation(e,'change');
        }


    }

    function validation(inputtxt,from) {
            var regx = props.regx;
            if(inputtxt==""){
                setipboxclass('ipbox')
            }
            else{
            if(inputtxt.match(regx)) {
                if(from=='focusout'){
                    
                    setipboxclass  ('ipboxok')
                }
                if(from=='change'){
                    setipboxclass('ipboxedit')
                }

                // sethaserror(props.name, false);
                // ipboxclass('ipboxedit')
                props.sethaserror(props.name, false);
            }  
            else {  
            //   sethaserror(props.name, true);
                props.sethaserror(props.name, true);
                setipboxclass('ipboxerror')
            }
        }
    }

    return (
        <>
            <div 
            // className={"ipbox"}
            className={ipboxclass}
            >
            {/* <div className={"itext"}> */}
                {/* <div className={'blockdiv'}> */}
                    <input
                        type={props.type}
                        // className={ipboxclass}
                        onFocus={(e)=>handlefocus(e)}
                        onBlur={(e)=>handlefocusout(e.target.value)}
                        value={props.value}
                        required={props.required}
                        name={props.name}
                        onChange={(e)=>handlechange(e.target.value)}
                        placeholder=" "
                        onMouseOut={(e)=>handleMouseEvent(e.target.value)}
                        // onSelect={(e)=>handlefocus(e)}
                    />
                    {/* <span className="iplabel">{props.label}</span> */}
                     <label>{props.label}</label>
                {/* </div> */}
            {haserror &&
                <div className={'errortxt'}>{props.errortext}</div>
            }

</div>





        </>
    )

}

export default Text;
