import React, { useContext } from "react";
import  Master  from "./Master";  // Assuming this is the context you are using for form data
import CollegeRequest from './CollegeRequest';  // Import your CollegeRequest component
import './CollegeProfile.css';
import Configuration from "./Configuration";
import CollegeFileUploads from "./CollegeFileUploads";
import Paymentinfo from "./Paymentinfo";

function CollegeProfile() {
  const { registrationformdata, logindata, updateregistrationformdata } = useContext(Master);  // Getting form data from context

  if (!registrationformdata) {
    return <p>Loading college profile data...</p>; // Show a loading message or spinner if data is unavailable
  }

  return (
    <div className="collegeprofile">
      <h2>College Profile Information</h2>     
      {/* Display profile data */}
      <div className="profileinfo">
        <p><strong>Institution/College Name:</strong> {registrationformdata.college}</p>
        <p><strong>Registration Number:</strong> {registrationformdata.registrationnumber}</p>
        <p><strong>College Address:</strong> {registrationformdata.collegeaddress}</p>
        <p><strong>Telephone Number:</strong> {registrationformdata.telephonenumber}</p>
        <p><strong>Email Address:</strong> {registrationformdata.emailaddress}</p>
        <p><strong>Principal’s Mobile Number:</strong> {registrationformdata.principalmobilenumber}</p>
        <p><strong>College Website URL:</strong> {registrationformdata.collegewebsite}</p>
      </div>

      {/* Display College Request form right after the profile information */}
      {/* <CollegeRequest /> */}

      {/* File Uploads Section */}
      <div className="college-file-uploads">
        <h2>College File Uploads</h2>
        <CollegeFileUploads />
      </div>

      {/* Payment Info Section */}
      <div className="payment-info">
        <h2>Payment Information</h2>
        <Paymentinfo />
      </div>
     
    </div>
  );
}

export default CollegeProfile;
