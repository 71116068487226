import React, { useState, useEffect,useContext } from 'react';  
import './Paymentaffiliation.css';
import axios from 'axios';
import Master from "./Master";

const Paymentaffiliation = () => {

  const {
    baseurl,userid, academicyearid,selectedrequestobject, token,roleid, setselectedrequestobject
 } = useContext(Master);




  const [isconfirmed, setisconfirmed] = useState(false);
  const [paymentdata, setpaymentdata] = useState([]);
  const [reload, setreload] = useState(false);

  const handlecheckboxchange = (event) => {
    setisconfirmed(event.target.checked);
  };


const handlerequestpayment=()=>{


  axios({
    url: baseurl+"/makepaymentrequest",
    method: "POST",
    headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`  ,
    },
 
      data: {
        affiliationrequestid:selectedrequestobject.requestid,
        paymenttypeid:2,
        status:0,
        amount:15000,
        bankrequestid:111111111
}


    
}
)
    .then((response) => {         
        if(response.data.success){

          setreload(!reload);

        }

    })
    .catch((error) => {

    });

}



const handlermakepayment=()=>{


  axios({
    url: baseurl+"/makepayment",
    method: "POST",
    headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`  ,
    },
 
      data: {
        affiliationrequestid:selectedrequestobject.requestid,
        paymenttypeid:2,
        status:1,
        utr:"9876789065"
        // amount:15000,
        // bankrequestid:
}
    
}
)
    .then((response) => {         
        if(response.data.success){
          setreload(!reload);
        }

    })
    .catch((error) => {

    });

}







useEffect(() => {


    axios({
      url: baseurl+"/getpaymentdetails",
      method: "POST",
      headers: {
           authorization: `Bearer ${localStorage.getItem("token")}`  ,
      },
      data: {affiliationrequestid:selectedrequestobject.requestid,
              userid:selectedrequestobject.userid,
              paymenttypeid:2
      },
      
  }).then((response) => {
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>                    ",response.data);
    
    if(response.data.success){
      
      if(response.data.data.length>0){
        
        setpaymentdata(response.data.data[0])
        
        if(response.data.data[0].status==1){
          

        setselectedrequestobject((prevdata)=>({...prevdata,showfinalcertificate:true}))
        }else{
          setselectedrequestobject((prevdata)=>({...prevdata,showfinalcertificate:false}))
          
        }
    }else{
      setselectedrequestobject((prevdata)=>({...prevdata,showfinalcertificate:false}))
      setpaymentdata({})
    }

  }  
})
.catch((error) => {
    
 
});


}, [,reload]);




  return (
  
    <>
    <div className='paymentwrapper'>
    {( roleid==process.env.REACT_APP_ADMIN || roleid==process.env.REACT_APP_COLLEGE )&& <>
    <div className="payment-container">
      <h2>Affiliation Payment</h2>
 
      { roleid == process.env.REACT_APP_ADMIN && (paymentdata.status==0 || paymentdata.status==null ) && <>
      <div className="checkbox-container">
        <input
          type="checkbox"
          id="accessor-fee"
          checked={isconfirmed}
          onChange={handlecheckboxchange}
        />
        <label htmlFor="accessor-fee">Confirm Affiliation Fee of Rs.200000</label>
      </div>
      
      <button
        className="request-button"
        disabled={!isconfirmed}
        onClick={() => handlerequestpayment()}
      >
        Request Affiliation Payment
      </button>


      {/* <button
        className="request-button"
        disabled={!isconfirmed}
        onClick={() => 
      >
        Request Payment
      </button> */}
</>
    }


{ paymentdata.status!=null && <>
<>

<h3>Status: {paymentdata.status==1?"Payment Successfull UTR:+ "+paymentdata.utr:"Payment Pending"}</h3>

</>


</>}

{ (roleid == process.env.REACT_APP_COLLEGE && paymentdata.status==null) && <>

Payment notification will be sent to you shortly.


</>
}

    {roleid == process.env.REACT_APP_COLLEGE && (paymentdata.status==0 ) &&<>
    
      <button
        className="request-button"
        // disabled={!isconfirmed}
        onClick={()=>handlermakepayment()}
      >
        Make Payment Rs 200000
      </button>
    
    </>}
    </div>
    </>}
    </div>
    </>
  );
};

export default Paymentaffiliation;
