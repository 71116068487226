import React, { useState, useEffect,useContext } from 'react';
import axios from 'axios';
import Master from './Master';
import './Requestlist.css';

const Requestlist = () => {

    const { 
        userid, 
        baseurl, 
        setroleid,
        roleid,
        academicyearid,token,
        newrequest,setnewrequest,
        setaffiliationrequestid,affiliationrequestid,
        selectedrequestobject, setselectedrequestobject,isloading, setisloading

        
      } = useContext(Master);
  const [data, setdata] = useState([]);
  const [error, seterror] = useState('');

  useEffect(() => {
    // Fetch data from the backend
    // axios
    //   .get('https://example.com/api/requests') // Replace with your backend API endpoint
    //   .then((response) => {
    //     setdata(response.data); // Assuming response.data is an array of objects
    //   }) 
    //   .catch((error) => {
    //     seterror('Failed to fetch data. Please try again later.');
    //     console.error(error);
    //   });
      setisloading(true);
    
    
    axios({
        url: baseurl+"/getrequests",
        method: "POST",
        headers: {
            authorization: `Bearer ${token}`  ,
        },
        data: {
            userid:parseInt(userid),
            roleid:parseInt(roleid),
            academicyearid:academicyearid
        },

        
    }
  )
        .then((response) => {
           
           
            if(response.data.success){
                setdata(response.data.data);
                // localStorage.setItem("token", response.data.data["token"]);
                // navigate("/home");
            }

            
  
        })
        .catch((error) => {
            
            
          console.error('There was an error registering the college!', error);
        })
        .finally(() => {
          setisloading(false);
        })
  }, [academicyearid]);




  function handleselectedrequest(item){

    setnewrequest(false);
    setselectedrequestobject(item);
    setaffiliationrequestid(item.requestid);
    
  }

  return (
    <div className="table-container">
      {roleid==process.env.REACT_APP_COLLEGE &&
      <div className="button-container">
        <button className="new-request-button" onClick={() => {setselectedrequestobject({});setnewrequest(true)}}>New Request</button>
      </div>
}
      {error && <p className="error-message">{error}</p>}
      <h3>Request Lists</h3>
      <table className="academic-table">
        <thead>
          <tr>
            <th>Academic Year</th>
            <th>Request ID</th>
            <th>College Name</th>
            <th>Request Type</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((item, index) => (
              <tr key={index} onClick={()=>{handleselectedrequest(item)}}>
                <td>{item.academicyearstart+' - '+(item.academicyearend+'').substring(2)}</td>
                <td>{item.requestid}</td>
                <td>{item.collegename}</td>
                <td>{item.requesttype}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="no-data">
                No data available.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {isloading && (
  <div className="loading-overlay">
    <div className="spinner"></div>
  </div>
)}
    </div>
  );
};

export default Requestlist;
