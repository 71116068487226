import React, { useContext, useState, useEffect } from 'react';
import Master from './Master';
import './affiliationoptions.css'


const Affiliationoptions = () => {
    const {
        roleid,baseurl, affiliationtypeid, setaffiliationtypeid,selectedrequestobject,isloading,setisloading
     } = useContext(Master);
     const [selectedparent, setselectedparent] = useState(null);
     const [selectedchild, setselectedchild] = useState(null);
   
     const handleradiochangeparent = (event) => {
       setselectedparent(parseInt(event.target.value, 10));
       if(event.target.value==0){
        //  setselectedchild(1);
       }else{
        setselectedchild(null); 
       }
       // Reset child selection when parent changes
     };
   
     const handleradiochangechild = (event) => {
       setselectedchild(parseInt(event.target.value, 10));
       setaffiliationtypeid(parseInt(event.target.value, 10));
     };
   

     useEffect(() => {
       if (selectedparent === 0) {
        if(selectedchild){
          setaffiliationtypeid(selectedchild);
           setisloading(true);
          
        }else{
          setaffiliationtypeid(selectedparent);
            setisloading(false);
          }
         // Reset child selection when parent changes
       }else{
        setaffiliationtypeid(selectedparent);
      setisloading(false);
      }

     }, [selectedparent,selectedchild]);

     useEffect(() => {
      // setisloading(true);
      if(Object.keys(selectedrequestobject).length>0  )
        {
        if(selectedrequestobject.requesttypeid==4 || selectedrequestobject.requesttypeid==5){
          setselectedparent(selectedrequestobject.requesttypeid);
        }else{
          setselectedparent(0);
          setselectedchild(selectedrequestobject.requesttypeid);
        }
        
      }
setisloading(false);
      return () => {
          // Anything in here is fired on component unmount.
      }
  }, [])
  

     return (
       <div className="affiliation-options-container">
         <h3>Affiliation Options</h3>
   
         {/* Parent Options */}
         <div className="parent-options">
           <label>
             <input
               type="radio"
               name="parent"
               value="0"
               checked={selectedparent === 0}
               onChange={handleradiochangeparent}
               disabled={Object.keys(selectedrequestobject).length>0}
             />
             Consent of Affiliation
           </label>
           <label>
             <input
               type="radio"
               name="parent"
               value="4"
               checked={selectedparent === 4}
               onChange={handleradiochangeparent}
               disabled={Object.keys(selectedrequestobject).length>0}
             />
             Provisional Affiliation
           </label>
           <label>
             <input
               type="radio"
               name="parent"
               value="5"
               checked={selectedparent === 5}
               onChange={handleradiochangeparent}
               disabled={Object.keys(selectedrequestobject).length>0}
             />
             Continuation/Renewal Affiliation
           </label>
         </div>
         {/* Child Options */}
         {selectedparent === 0 && (<><h3>Affiliation Type</h3>
           <div className="child-options">
             <label>
               <input
                 type="radio"
                 name="child"
                 value="1"
                 checked={selectedchild === 1}
                 onChange={handleradiochangechild}
                 disabled={Object.keys(selectedrequestobject).length>0}
                 
               />
               Starting a new college
             </label>
             <label>
               <input
                 type="radio"
                 name="child"
                 value="2"
                 checked={selectedchild === 2}
                 onChange={handleradiochangechild}
                 disabled={Object.keys(selectedrequestobject).length>0}
               />
               Adding new courses
             </label>
             <label>
               <input
                 type="radio"
                 name="child"
                 value="3"
                 checked={selectedchild === 3}
                 onChange={handleradiochangechild}
                 disabled={Object.keys(selectedrequestobject).length>0}
               />
               Increasing seat numbers
             </label>
           </div>
           </>
         )}
   
   {isloading && (
  <div className="loading-overlay">
    <div className="spinner"></div>
  </div>
)}
         {/* Selection Summary */}
       </div>
     );
   };
   
export default Affiliationoptions;
