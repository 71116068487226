import React, { useContext, useEffect, useState } from "react";
import RegistrationContext from "./Master";
import "./RegistrationForm.css";
import Button from "./elements/button/Button";
import Text from "./elements/text/Text";
import Master from "./Master";
// import Dropdown from "./elements/dropdown/Dropdown";
import Select from "react-dropdown-select";
import Dropdown from "./elements/dropdown/Dropdown";
import {options} from './dropDownOptions';
import CollegeProfile from "./CollegeProfile";
import axios from "axios";
import UserLogin from './UserLogin';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function RegistrationForm() {
  const { registrationformdata, updateregistrationformdata,
    isregistered,updateisregistered,
    selectedoption,setSelectedOption,
     managementtypes, setmanagementtypes,baseurl,
     updateregistrationformvalidationdata,
     registrationformvalidationdata,doesformhaserror,isloading, setisloading

   } = useContext(Master);
const navigate = useNavigate();
//var msg = " Registration successful! Thank you for signing up.";

const [formhaserror,setformhaserror] = useState(true);

const[successMessage, setsuccessMessage] = useState("");
    const[errorMessage, seterrorMessage,] = useState("");


  const handlechange = (name,value) => {
    updateregistrationformdata(name, value);
   
  };

  const updateformvalidationdata = (name,value) => {
    
    updateregistrationformvalidationdata(name, value);

  
  };
  const handlelogin = () => {
    navigate("/login");

};

  useEffect (() => {
    var haserrors=doesformhaserror(registrationformvalidationdata);
    setformhaserror(haserrors)

  }, [registrationformvalidationdata]);



  const handlesubmit = (e) => {
    e.preventDefault();
    seterrorMessage("");
    setisloading(true);
   
    axios({
      url: baseurl+"/register",
      method: "POST",
      headers: {
          authorization: "your token comes here",
      },
      data: registrationformdata,
      
  }
)
      .then((response) => {

  //       if (response.data.success) {  // Assuming response.data.success indicates success
  //         setsuccessMessage(true); // Update the success state
  //       }
  //       
  //       updateisregistered(true);  

  //     })
  //     .catch((error) => {
  //       console.error('There was an error registering the college!', error);
  //     });
  // };
  if (response.data.success) {
    setsuccessMessage(true);
    // updateisregistered(true);
  } else {
    seterrorMessage(response.data.message || "Registration failed. Please try again.");
  }
})
.catch((error) => {
  seterrorMessage(error.response?.data?.message || "An error occurred during registration.");
})

.finally(() => {
  setisloading(false); // Stop loading
});
};

  useEffect (() => {

    
    setisloading(true);
    
    axios.get(baseurl + "/getmanagementtypes")
      .then(response => {
        
        if(response.data.success){
          setmanagementtypes(response.data.data);
          
          
        }
        
        if(!response.data.success){
          alert(response.data.message);
        }
        
       
      })
      .catch(err => {
        //alert("unexpected error");
        console.error("Error fetching management types:", err);
        // setError("Failed to fetch management types"); // Set error state
    
      })
      .finally(() => {
        setisloading(false); // Stop loading
      });
  }, []);



 
  return (
    <div className="registrationform">
      <h2>College Registration Form</h2>
      {/* {!successMessage ? (  */}
      <form onsubmit={handlesubmit}>
        <div className="registerinputs">
        <Text
          label="Institution/College Name"
          name="collegename"
          value={registrationformdata.college}
          update={(value) => handlechange("collegename",value)}

          required={true}
          errortext="College name is required"
          sethaserror={updateformvalidationdata}
        />

      <Dropdown
        options={managementtypes}
        name = {"managementtypeid"}
        labelField="label"
        valueField="value"
        className="custom-select"
        // onChange={handleChange}
        onChange={(name, value) => {
          handlechange(name, value);
          updateformvalidationdata(name, value);}}
        values={managementtypes.filter((opt) => opt.value === selectedoption?.value)}
        placeholder="Select Management Type"
        
      />

        {/* Registration Number */}
        <Text
          label="Registration Number of the Managing Body"
          name="registrationnumber"
          className = "registerinputfields"
          value={registrationformdata.registrationnumber}
          update={(value) => handlechange("registrationnumber", value)}
          regx={/^[a-zA-Z0-9]+$/} // Alphanumeric regex for registration number
          required={true}
          errortext="Please enter a valid registration number"
          sethaserror={updateformvalidationdata}
        />

        {/* College Address and Contact Details */}
        <Text
          label="College Address"
          className = "registerinputfields"
          name="address"
          value={registrationformdata.collegeaddress}
          update={(value) => handlechange("address", value)}
          required
          errortext="Please provide the college address"
          sethaserror={updateformvalidationdata}
        />

        <Text
          label="Telephone Number"
          className = "registerinputfields"
          name="telephone"
          value={registrationformdata.telephonenumber}
          update={(value) => handlechange("telephone", value)}
          regx={/^\d{10}$/} // Regex for 10-digit telephone number
          required
          errortext="Please enter a valid telephone number"
          sethaserror={updateformvalidationdata}
        />

        <Text
          label="Email Address"
          className = "registerinputfields"
          name="email"
          value={registrationformdata.emailaddress}
          update={(value) => handlechange("email", value)}
          regx={/^\S+@\S+\.\S+$/} // Email regex for validation
          required={true}
          errortext="Please enter a valid email address"
          sethaserror={updateformvalidationdata}
        />

        {/* Principal's Mobile Number */}
        <Text
          label="Principal’s Mobile Number"
          className = "registerinputfields"
          name="principalmobile"
          value={registrationformdata.principalmobilenumber}
          update={(value) => handlechange("principalmobile", value)}
          regx={/^\d{10}$/} // Regex for 10-digit mobile number
          required={true}
          errortext="Please enter a valid mobile number"
          sethaserror={updateformvalidationdata}
        />

        {/* College Website URL */}
        <Text
          label="College Website URL"
          className = "registerinputfields"
          name="websiteurl"
          value={registrationformdata.collegewebsite}
          update={(value) => handlechange("websiteurl", value)}
          regx={/^https?:\/\/(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/} // Regex for URL
          required={true}
          errortext="Please enter a valid website URL"
          sethaserror={updateformvalidationdata}
        />
         </div>
        {/* Submit Button */}
        <div className="registerbutton"> 
        <Button
         disabled={formhaserror}
          onclick={handlesubmit}
          className="submit-button"
          label="Register"
        />
       
        </div>
        <span className="forgot-password" onClick={()=>{handlelogin();seterrorMessage(""); setsuccessMessage("");}}> Login?</span>
      </form>
        {/* //      ) : (
        // <div className="success-message">
        //   <p style={{ color: "green" }}>
        //     Registration successful! Thank you for signing up.
        //   </p>
        //   <p>
        //     <Link to="/login">Click here to login</Link> 
        //     {/* <a href="./UserLogin.js">Click here to login</a> */}
           {/* </p>  */}
        {successMessage && (
          <div className="success-message">
          <p className="registermessage">
            Registration successful! Thank you for signing up.
          </p>
          <p className="registermessage">Your login details have been sent to your email. Please check your inbox and log in.</p>
          <p className="registermessage">
            <Link to="/login" onClick={()=>{seterrorMessage("");setsuccessMessage("");}}>Click here to login</Link> 
            {/* <a href="./UserLogin.js">Click here to login</a> */}
          </p>
          </div>
        )}
        
        {errorMessage && (
          <div className="error-message">
          <p className="registermessage">
            {errorMessage}
          </p>
          </div>
        )}
        {isloading && (
  <div className="loading-overlay">
    <div className="spinner"></div>
  </div>
)}

        {/* </div> */}
       {/* )}  */}
    </div>
  );
}

export default RegistrationForm;
