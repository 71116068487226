
import React, { useContext, useEffect, useState } from 'react';
import styles from './Footer.module.css';
import Maincontent from './Maincontent';
import UserLogin from './UserLogin';
import Master from './Master';
import RegistrationForm from './RegistrationForm';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CollegeProfile from './CollegeProfile';
import Configuration from './Configuration';
import Login from './UserLogin';
import Header from './Header'
import './Layout.css';
import Profile from './Profile';
import AcademicYearInput from './AcademicYearInput';
import Sidebar from './Sidebar';
import Courseconfigurationug from './Courseconfigurationug';
import Courseconfigurationpgmdms from './Courseconfigurationpgmdms';
import Courseconfigurationpgmds from './Courseconfigurationpgmds';
import Collegetableug from './Collegetableug';
import Collegetablepgmdms from './Collegetablepgmdms';
import Collegetablepgmds from './Collegetablepgmds';
import Programselector from './Programselector';
import Affiliationoptions from './Affiliationoptions';
import UploadCollegeFiles from './UploadCollegeFiles';
import Assessorreport from './Assessorreport';
import SelectAssessor from './SelectAssessor';
import Finalcertificate from './Finalcertificate';
import Paymentassessor from './Paymentassessor'
import Paymentprovisional from './Paymentprovisional';
import BigFileUpload from './BigFileUpload';
import Requestlist from './Requestlist';
import { useNavigate } from "react-router-dom";
import Paymentaffiliation from './Paymentaffiliation';
import Courseconfiguration from './Courseconfiguration';
import Assor from './Assor';

const Workflow = () => {
  const navigate = useNavigate();
  const {
    setuserid, setroleid, roleid, userid, baseurl, setnewrequest, newrequest,
    affiliationtypeid, selectedprogram, pgsuboptions, setpgsuboptions,
    affiliationrequestid, selectedrequestobject, academicyearid,
    setselectedrequestobject

  } = useContext(Master);

  useEffect(() => {
    // window.scrollTo(0, 0);

    if (localStorage.getItem('token') == null && localStorage.getItem('userid') == null && localStorage.getItem('roleid') == null) {
      navigate("/login");
    } else {
      

      setuserid(localStorage.getItem('userid'));
      setroleid(localStorage.getItem('roleid'));
      navigate("/home");
    }


  }, []);

  
  

  
  return (
    <div className="workflow">

      <Routes>


        <Route path="/login" element={<UserLogin />} />
        <Route path="/register" element={<RegistrationForm />} />


        <Route path="/home" element={

          <>
            {/* {
              (roleid == process.env.REACT_APP_COLLEGE) &&
              <AcademicYearInput />
             } */}
             <AcademicYearInput />

            {

              (((roleid == process.env.REACT_APP_ADMIN) && selectedrequestobject.userid) ||

                ((roleid == process.env.REACT_APP_ASSESSOR) && selectedrequestobject.userid) || (roleid == process.env.REACT_APP_COLLEGE))

              &&
              (
                

                <Profile />
              )}
              
{/* <div className='homebtnreqbtn' style={{display: 'flex'}}>
<button className='bluebutton' onClick={()=> window.location.reload()}>Home</button>

</div> */}

            {
              (Object.keys(selectedrequestobject).length == 0 && ((newrequest == null) || (newrequest == false)))

              &&
              <>
            
              
                <Requestlist />

              </>
            }

            {
              (

                ((roleid == process.env.REACT_APP_ADMIN) && (typeof selectedrequestobject.requestid != 'undefined')) ||

                ((roleid == process.env.REACT_APP_ASSESSOR) && typeof selectedrequestobject.userid != 'undefined') ||

                ((roleid == process.env.REACT_APP_COLLEGE) && (newrequest == true || typeof selectedrequestobject.userid != 'undefined'))

              )

              &&
              <>
                <Affiliationoptions />
                {

                  (affiliationtypeid != null && affiliationtypeid !=0) &&
                  <>

                    <Programselector />

                    {(selectedprogram == 'UG') &&
                      <>
                        <Collegetableug />
                      
                       
                        {/* <UploadCollegeFiles /> */}
                        {
                      
                       ( (affiliationrequestid != null)||(selectedrequestobject.requestid != null))&&(
                          <UploadCollegeFiles />)}
                      </>
                    }
                    {selectedprogram == 'PG' &&
                      <>
                        {pgsuboptions.md_ms && <Collegetablepgmdms />}
                        {pgsuboptions.mds && <Collegetablepgmds />}

                        {/* {(pgsuboptions.md_ms || pgsuboptions.mds)
                          &&
                          <UploadCollegeFiles />
                        } */}
                         {
                        (((affiliationrequestid!=null)||(selectedrequestobject.requestid != null))&& (pgsuboptions.md_ms || pgsuboptions.mds))&&(
                          <UploadCollegeFiles />)}

                      </>

                    }
                    {/* {
                      ((roleid == process.env.REACT_APP_COLLEGE)&& affiliationrequestid != null)&&(
                        <UploadCollegeFiles />
                      )
                    } */}
                   
                    {/* {
                   ((roleid == process.env.REACT_APP_COLLEGE)&& (affiliationrequestid != null)&&(Object.keys(selectedrequestobject).length>0))&&(
                     <UploadCollegeFiles />
                   )
                    } */}

                    {(selectedrequestobject.managementtypeid == 1) &&
                      <>
                        <Finalcertificate />
                      </>

                    }

                    {
                      (selectedrequestobject.managementtypeid != 1 &&
                        selectedrequestobject.requesttypeid == 4) &&
                      <>
                        {selectedrequestobject.status == 1 && /* roleid != process.env.REACT_APP_ASSESSOR && */
                          <Paymentprovisional />
                        }
                        {selectedrequestobject.showassessorselection == true && /* roleid != process.env.REACT_APP_COLLEGE && */
                          <SelectAssessor />

                        }
                        {selectedrequestobject.showassessorreport == true && /* roleid != process.env.REACT_APP_COLLEGE  && */
                          <Assor />
                         }

                        {/* {selectedrequestobject.showaffiliationpayment == true &&
                          <Paymentaffiliation />

                        } */}
                        {selectedrequestobject.showfinalcertificate == true && /* roleid != process.env.REACT_APP_ASSESSOR && */
                          <Finalcertificate />
                        }
                      </>
                    }


                    {
                      (selectedrequestobject.managementtypeid != 1 &&
                        selectedrequestobject.requesttypeid == 5) &&
                      <>


                        {selectedrequestobject.status == 1 && /* roleid != process.env.REACT_APP_ASSESSOR && */
                          <Paymentaffiliation />
                        }
                        {selectedrequestobject.showfinalcertificate == true && /* roleid != process.env.REACT_APP_ASSESSOR && */
                          <Finalcertificate />
                        }
                      </>
                    }
                      {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                    {
                      (selectedrequestobject.managementtypeid != 1 &&
                        (selectedrequestobject.requesttypeid == 1 || selectedrequestobject.requesttypeid == 2 || selectedrequestobject.requesttypeid == 3)) &&
                      <>
                      {/* <Assor/> */}
                        {/* selectedrequestobject.status == 1 && */  /* roleid != process.env.REACT_APP_ASSESSOR &&  */
                        <>
                          <Paymentassessor />
                          
                      </>
                        }
                        {selectedrequestobject.showassessorselection == true && /* roleid != process.env.REACT_APP_COLLEGE && */
                        <> 
                          <SelectAssessor />
                          </>

                        }
                        {selectedrequestobject.showassessorreport == true && /* roleid != process.env.REACT_APP_COLLEGE && */
                          // <Assessorreport />
                          <Assor />
                        }
                        {selectedrequestobject.paymentaffiliation == true &&/*  roleid != process.env.REACT_APP_ASSESSOR && */
                          <Paymentaffiliation />
                        }

                        {selectedrequestobject.showfinalcertificate == true && /* roleid != process.env.REACT_APP_ASSESSOR && */
                          <Finalcertificate />
                        }
                      </>
                    }

                    {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                  
                    </>
                  
                }


              </>

            }












          </>


        }


















        />

        {/* ////////////////////////////////////////////////////// */}


        <Route path="/configuration" element={

          (roleid == process.env.REACT_APP_ADMIN) &&
          <>
            <AcademicYearInput />
            {/* <Courseconfiguration/> */}
            <Courseconfigurationug />
            <Courseconfigurationpgmdms />
            <Courseconfigurationpgmds />

          </>


        } />



      </Routes>






    </div>
  );
};

export default Workflow;

//   );
// }

// export default Layout;