import React, { useContext, useState } from "react";
import axios from "axios";
import Button from "./elements/button/Button";
import Master from "./Master";
import "./Paymentinfo.css"
function Paymentinfo(){

    const {isrequestpayment, setisrequestpayment, baseurl,registrationFormData, isloading, setisloading} = useContext(Master);
    const [showproceedtopay, setshowproceedtopay] = useState(false);
    const handlesubmit = (e) =>{
        e.preventDefault();
        setisloading(true);
        axios({
            url: baseurl+"/payment",
            method: "POST",
            headers: {
                authorization: "your token comes here",
            },
            //data: paymentinfo,
            
        }
      )
            .then((response) => {
              setisrequestpayment(true);
              setshowproceedtopay(true);  
            })
            .catch((error) => {
            })
            .finally(() => {
              setisloading(false);
            });
        };

    

    return(
      <div className='paymentwrapper'>
        <div className="paymentInfo">
            <div className="Fee Details">
                <h3>Fee Details</h3><br></br><br></br>

            </div>
            <div className="requestpayemt">
           <div className="paymentbutton">   
            <Button
          onclick={handlesubmit}
          className="request-payment-button"
          label="Request Payment"
        />
        </div>
</div>
{/* Proceed to Pay Section */}
{showproceedtopay && (
        <div className="proceed-to-pay">
          <h3>Proceed to Pay</h3>
          <p>Payment details are now ready. Click below to continue:</p>
          <div className="paymentbuttonforcolleg">
          <Button
            onclick={() => setisrequestpayment(false)}
            className="proceed-payment-button"
            label="Proceed to Pay"
          />
          </div>
        </div>
       )} 
       {isloading && (
  <div className="loading-overlay">
    <div className="spinner"></div>
  </div>
)}

        </div>
        </div>
    )
}
export default Paymentinfo;