

import React, { useState, useEffect,useContext } from 'react';
import axios from 'axios';
import Master from './Master';
import './collegetable.css';

const Collegetableug = () => {
      const {
        roleid,baseurl,  affiliationrequestsubmissioncompletion,
         setaffiliationrequestsubmissioncompletion,
         updateshowformsubmissionbutton,formsubmissionbutton,
         selectedrequestobject,updatepgsuboptions,setaffiliationtypeid,affiliationrequestid,
         setaffiliationrequestid, affiliationtypeid,userid, academicyearid
     } = useContext(Master);
  const [tabledata, settabledata] = useState([]);
  const [modifiedrows, setmodifiedrows] = useState([]); // To collect edited rows



    useEffect (() => {
      if ( Object.keys(selectedrequestobject).length==0 ){
      setaffiliationrequestsubmissioncompletion((prevData) =>({ ...prevData, ['pgmdmscoursesubmissionscomplete']: null }))

        axios({
          url: baseurl+"/pgcoursemdms",
          method: "GET",
          headers: {
               authorization: `Bearer ${localStorage.getItem("token")}`  ,
          },
          data: {},
          
      }).then((response) => {
        if(response.data.success){
    
          var result = response.data.data.filter(obj => {
            return obj.category === "MD/MS"
          })
          settabledata(result)
        //   setaddedRows([]);
        //   setmodifiedRows([]);
        }else{
    
        }
    })
    .catch((error) => {
        
     
    });
  }
      
      }, []);



      



  const handlecheckboxchange = (index, checked) => {
    const updateddata = [...tabledata];
    updateddata[index].editable = checked;
    settabledata(updateddata);
  };

  const handleinputchange = (index, value) => {
    const updateddata = [...tabledata];
    const intake = parseInt(updateddata[index].intake, 10);
    const collegeintake = parseInt(value, 10);

    // Ensure college_intake does not exceed intake
    if (collegeintake <= intake || isNaN(collegeintake)) {
      updateddata[index].noofstudents = value;
      updateddata[index].courseid=updateddata[index].id;
      // Add the modified row to the modifiedrows array
      const modifiedrow = updateddata[index];
      setmodifiedrows((prev) => {
        const exists = prev.find((row) => row.id === modifiedrow.id);
        if (exists) {
          // Replace the existing row in the array
          return prev.map((row) =>
            row.id === modifiedrow.id ? { ...modifiedrow } : row
          );
        }
        // Add the new modified row to the array
        return [...prev, { ...modifiedrow }];
      });

      settabledata(updateddata);
    } else {
      alert(`College intake cannot exceed ${intake}`);
    }
  };

  const handleformsubmit = async () => {
    try {

    // var response =  await axios.post(baseurl+"/uploadrequestedcourse", modifiedrows,{ 
    //   headers: {
    //     authorization: `Bearer ${localStorage.getItem("token")}`
    // }});

    axios({
      url: baseurl+"/uploadrequestedcourse",
      method: "POST",
      headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
      },
      data: {
          userid:parseInt(userid),
          roleid:parseInt(roleid),
          academicyearid:parseInt(academicyearid), // Replace with the actual academicyearid
          affiliationtypeid:parseInt(affiliationtypeid),
          courses:modifiedrows,
          affiliationrequestid:affiliationrequestid
      },

      
  }
)


.then((response) => {
  alert('Data saved successfully!');
  if(response.data.success){
    setaffiliationrequestid(response.data.data.affiliationrequestid);
    setaffiliationrequestsubmissioncompletion((prevData) =>({ ...prevData, ['pgmdmscoursesubmissionscomplete']: true }))
    
}else{
  setaffiliationrequestsubmissioncompletion((prevData) =>({ ...prevData, ['pgmdmscoursesubmissionscomplete']: false }))

}

 }).catch((error) => {
  setaffiliationrequestsubmissioncompletion((prevData) =>({ ...prevData, ['ugcoursesubmissionscomplete']: false }))

console.error('There was an error registering the college!', error);
});


    } catch (error) {
      setaffiliationrequestsubmissioncompletion((prevData) =>({ ...prevData, ['pgmdmscoursesubmissionscomplete']: false }))

      console.error('Error saving data:', error);
      alert('Error saving data');
    }
  };


  useEffect(() => {

    updateshowformsubmissionbutton();

  }, [setaffiliationrequestsubmissioncompletion]);



  useEffect(() => { 
    if ( Object.keys(selectedrequestobject).length>0 ) {
      /////////////////////////////////////////////////////////////////
      axios({
        url: baseurl + "/getrequestedcourses",
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {requestid:selectedrequestobject.requestid},

      }).then((response) => {
        if (response.data.success) {

          setaffiliationrequestid(response.data.data.affiliationrequestid)
          // var result = response.data.data.filter(obj => {
          //   return obj.program === "PG"
          // })

          var result = response.data.data.filter(obj => {
            return obj.category === "MD/MS"
          })

          if(result.length>0){
            updatepgsuboptions("MD/MS", true);
          }
          settabledata(result)
        } 
        
        else {

        }
      })
        .catch((error) => {

          alert("Unexpexted error occured. Please try again later.");
        });
      ///////////////////////////////////////////////////////////////////


    }

    return () => {
        // Anything in here is fired on component unmount.
    }


}, [])





  return (
    <div className="collegetable-container">
       <h3>Enter PG MD/MS Courses</h3>
      <table className="collegetable">
        <thead>
          <tr>
            <th>Select</th>
            {/* <th>Program</th> */}
            {/* <th>Category</th> */}
            <th>Course</th>
            {/* <th>Intake</th> */}
            {/* <th>Price</th> */}
            <th>College Intake</th>
          </tr>
        </thead>
        <tbody>
          {tabledata.map((row, index) => (
            <tr key={row.id}>
              <td>
                <input
                  type="checkbox"
                  checked={row.editable}
                  onChange={(e) => handlecheckboxchange(index, e.target.checked)}
                />
              </td>
              {/* <td>{row.program}</td> */}
              {/* <td>{row.category}</td> */}
              <td>{row.course}</td>
              {/* <td>{row.intake}</td> */}
              {/* <td>{row.price}</td> */}
              <td>
                <input
                  type="number"
                  value={(selectedrequestobject).length==0?row.college_intake:row.noofstudents}
                  onChange={(e) => handleinputchange(index, e.target.value)}
                  placeholder={`Max ${row.intake}`}
                  disabled={!row.editable} // Disable if checkbox is not checked
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className='bluebutton' onClick={handleformsubmit}>Save & Continue</button>
    </div>
  );
};

export default Collegetableug;
