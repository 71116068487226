import React, { createContext, useState } from "react";

const Master = createContext();

export const MasterContext = ({ children }) => {
  const [registrationformdata, setregistrationformdata] = useState({
    managementtypeid:null,
  });
  const [loginformdata, setloginformdata] = useState({});
  const [isloggedin, setisloggedin] = useState(false);
  const [isregistered, setisregistered] = useState(false);
  const [isrequestpayment, setisrequestpayment] = useState(false);
  const [academicyear, setacademicyear] = useState("");
  const [program, setprogram] = useState("");
  const [selectedcourses, setselectedcourses] = useState([]);
  const [courseinput, setcourseinput] = useState("");
  const [activetab, setactivetab] = useState("configuration");
  const [theroute, settheroute] = useState("");
  const [forgotpassword, setforgotpassword] = useState(false);
  const [selectedoption, setselectedoption] = useState(null);
  const baseurl = process.env.REACT_APP_BASE_URL;
  const [managementtypes, setmanagementtypes] = useState([]);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(null);
  const [academicyearAdmin, setacademicyearadmin] = useState("");
  const [userid,setuserid]=useState(null);
  const [registrationformvalidationdata, setregistrationformvalidationdata] = useState({
    collegename: null,
    managementtypeid: null,
    registrationnumber: null,
    address: null,
    telephone: null,
    email: null,
    principalmobile: null,
    websiteurl: null,

  });
  const [loginformvalidationdata, setloginformvalidationdata] = useState({
    email: null,
    password: null,
  });

  const [programlist, setprogramlist] = useState([]);
  const [loggedinuserdata, setloggedinuserdata] = useState({});
  const [newrequest,setnewrequest]=useState(false);

  ////////////////////
  const [roleid,setroleid]=useState(null);
  const [managementtype,setmanagementtype]=useState('');
  /////////////////////////////////////////////////////////////////////////////

  const [fromyear, setfromyear] = useState('');
  const [toyear, settoyear] = useState('');
  const [iscurrent, setiscurrent] = useState(false);
  const [academicyearid, setacademicyearid] = useState(false);
  const [selectedprogram, setselectedprogram] = useState('');
  const [collegrequestedocs,setcollegrequestedocs]=useState([]);
  const [affiliationrequestid, setaffiliationrequestid] = useState(null);
  const [newaffiliationrequest, setnewaffiliationrequest] = useState(null);
  const [accessorrequestdocs,setaccessorrequestdocs]=useState([]);
  const [affiliationtypeid, setaffiliationtypeid] = useState(null);
  const [finalcertificatedoc,setfinalcertificatedoc]=useState(null);

  const [pgsuboptions, setpgsuboptions] = useState({
    md_ms: false,
    mds: false,
  });

  const [selectedrequestobject, setselectedrequestobject] = useState({});
  const [affiliationrequestsubmissioncompletion, setaffiliationrequestsubmissioncompletion] = useState({});
  const [isloading, setisloading] = useState(false);
  const [showformsubmissionbutton, setshowformsubmissionbutton] = useState(null);
  const [successmessage, setsuccessmessage] = useState(false); 
  const [errormessage, seterrormessage] = useState("");
  const updateregistrationformdata = (field, value) => {
    setregistrationformdata((prevdata) => ({ ...prevdata, [field]: value }));
  };

  const updateregistrationformvalidationdata = (field, value) => {
    setregistrationformvalidationdata((prevData) => ({ ...prevData, [field]: value }));
  };


  const doesformhaserror = (formvalidationData) => {

    var ithaserror= Object.values(formvalidationData).some(
      (value) => value === null || value === "" || value === true
    );
    return ithaserror;
  };
const updateloginformdata = (field, value) => {
  setloginformdata((prevdata) => ({...prevdata,[field]:value}));
};
const updateloginformvalidationdata = (field, haserror) => {
  setloginformvalidationdata((prevstate) => ({ ...prevstate, [field]: haserror }))

};


const updateshowformsubmissionbutton=()=> {

// 

// if((Object.values(affiliationrequestsubmissioncompletion).length>0  )){

//   if((Object.values(affiliationrequestsubmissioncompletion).includes(null)  )||
//     Object.values(affiliationrequestsubmissioncompletion).includes(false)){
//       
      
//       setshowformsubmissionbutton(false)
//   }
//   else{
//     
//     setshowformsubmissionbutton(true)
//   }

// }else{
//   setshowformsubmissionbutton(false)
// }


const values = Object.values(affiliationrequestsubmissioncompletion);
const allCompleted = values.length > 0 && values.every((value) => value === true);

setshowformsubmissionbutton(allCompleted);

};

const updateselectedprogram=(selectedprogram)=> {
  setselectedprogram(selectedprogram);
}

const updatepgsuboptions=(name,checked)=> {
  setpgsuboptions((prev) => ({
    ...prev,
    [name]: checked,
  }));
}




  return (
    <Master.Provider value={{  registrationformdata,
      updateregistrationformdata,
      loginformdata,
      updateloginformdata,
      activetab,
      setactivetab,
      academicyear,
      setacademicyear,
      program,
      setprogram,
      selectedcourses,
      setselectedcourses,
      courseinput,
      setcourseinput,
      selectedoption,
      setselectedoption,
     forgotpassword, 
     setforgotpassword,
     baseurl,
     managementtypes,
     setmanagementtypes,
     updateregistrationformvalidationdata,
     registrationformvalidationdata,doesformhaserror, loginformvalidationdata,
        updateloginformvalidationdata,isrequestpayment, setisrequestpayment,
        setregistrationformvalidationdata,
        fromyear, setfromyear,
        toyear, settoyear,
       iscurrent, setiscurrent,
       userid,setuserid,
       academicyearid, setacademicyearid,
       selectedprogram, setselectedprogram,
      //  collegedocs,setcollegedocs,
       collegrequestedocs,setcollegrequestedocs,
       newaffiliationrequest, setnewaffiliationrequest,
       affiliationrequestid, setaffiliationrequestid,
       accessorrequestdocs,setaccessorrequestdocs,
       roleid,setroleid,
       newrequest,setnewrequest,
       affiliationtypeid, setaffiliationtypeid,
       pgsuboptions, setpgsuboptions,
       selectedrequestobject, setselectedrequestobject,
       affiliationrequestsubmissioncompletion, setaffiliationrequestsubmissioncompletion,
       setshowformsubmissionbutton, showformsubmissionbutton,
       updateshowformsubmissionbutton,
       updateselectedprogram,updatepgsuboptions,
       managementtype, setmanagementtype,
       finalcertificatedoc,setfinalcertificatedoc,errormessage, seterrormessage,successmessage, setsuccessmessage,
       isloading, setisloading
}}>
      {children}
    </Master.Provider>
  );
};

export default Master;
