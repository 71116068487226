import React, { useContext, useState,useEffect } from 'react';
//import {ColorRing} from 'react-loader-spinner';
import Master from './Master';
import Text from './elements/text/Text'; // Import your custom Text component
import Button from './elements/button/Button'; // Import your custom Button component
import UserLogin from './UserLogin.css'; // Import CSS for styling (if needed)
import CollegeProfile from './CollegeProfile';
import { useNavigate } from "react-router-dom";
import {options} from './dropDownOptions';
import axios from "axios";


const Login = () => {
    const navigate = useNavigate();
    const {   loginformdata,
        updateloginformdata,  theroute, settheroute, forgotpassword, baseurl, 
        setforgotpassword, loginformvalidationdata, updateloginformvalidationdata,doesformhaserror,
        setregistrationformvalidationdata,setroleid,  userid,setuserid, isloading, setisloading
        
     } = useContext(Master);
    const [isloggedin, setisloggedin] = useState(false);
    const [formhaserror, setformhaserror] = useState(true);
    const[successMessage, setsuccessMessage] = useState("");
    const[errorMessage, seterrorMessage,] = useState("");

    const handlechange = (field, value) => {
        updateloginformdata(field, value);   
 
    };

      const updateformvalidationdata = (name,value) => {
            updateloginformvalidationdata(name, value);

     };

// Iu40XbGaPF   

     useEffect (() => {
        // setisloading(true);
        var haserrors=doesformhaserror(loginformvalidationdata);
        setformhaserror(haserrors)   
      }, [loginformvalidationdata]);
   
     const handlelogin = (e) => {
       // setIsLoggedIn(true);
       e.preventDefault();
       seterrorMessage("");
       setisloading(true);
    
       const token = localStorage.getItem("accessToken");
       
        axios({
            url: baseurl+"/login",
            method: "POST",
            headers: {
                authorization: `Bearer ${token}`  ,
            },
            data: loginformdata,
            
        }
      )
            .then((response) => {
               
               
                if(response.data.success){

                    localStorage.setItem("token", response.data.data["token"]);
                    setuserid(response.data.data["userid"]);
                    setroleid(response.data.data["roleid"]);
                    localStorage.setItem("userid", response.data.data["userid"]);
                    localStorage.setItem("roleid", response.data.data["roleid"]);
                    setsuccessMessage(response.data.message);
                    setisloggedin(true);  
                    navigate("/home");
                }
                else{
                    seterrorMessage(response.data.message);
                  
                }
            })
            .catch((error) => {
              console.error('There was an error registering the college!', error);
              seterrorMessage(error);

            })
            .finally(() => {
                setisloading(false);
            })
      };

    const handleregister = () => {
        navigate("/register");

    };

    const handleforgotpassword = (e) => {
        setforgotpassword(true);
        e.preventDefault();
       setisloading(true);
       seterrorMessage("");
        axios({
            url: baseurl+"/forgotpassword",
            method: "POST",
            data: loginformdata,
        }
      )
            .then((response) => {
             
                if(response.data.success){
                    setsuccessMessage(response.data.message);
                    //navigate("/home");
                }
                else{
                     seterrorMessage(response.data.message);
                }

          
      
            })
            .catch((error) => {
                
              console.error('There was an error registering the college!', error.re);
              
              seterrorMessage(error.data.message);
            })
            .finally(() => {
                setisloading(false);
            })
    };


    const handlenewregistration = () => {
        // Navigate to forgot password page or show modal
        navigate("/register");
    };

    // if (isloggedin) {
    //     return <Profile />; // Show CollegeProfile when user is logged in
    //   }
    
    return (
        <div className="login-container">
            {!forgotpassword  &&
            <h2>Login</h2>
            }

            {forgotpassword  &&
            <h2>Reset Password</h2>
            }
            <form className="login-form">
                {/* User ID Field */}

            

                <Text
                    label="Email"
                    name="email"
                    value={loginformdata.email}
                    update={(value) => handlechange('email', value)}
                    required
                    regx={/^\S+@\S+\.\S+$/} // Email regex for validation
                    errortext="Please enter a valid email address"
                    sethaserror={updateformvalidationdata}
                />

            {!forgotpassword  &&  <Text
                    label="Password"
                    name="password"
                    type="password"
                    value={loginformdata.password}
                    update={(value) => handlechange('password', value)}
                    required
                    //secureTextEntry
                    // autoCorrect={false}
                    regx={/^[\s\S]{8,10}$/}
                    errortext="Password must be at least 8 characters long, contain at least one uppercase letter, one number, and one special character."
                    // sethaserror={updateLoginFormValidation}
                    sethaserror={updateformvalidationdata}
                />
            }

                {/* Login Button */}
                {!forgotpassword &&
                <Button
                    label="Login"
                    onclick={handlelogin}
                    className="login-button"
                    disabled={formhaserror}
                />
                }

                {forgotpassword &&
                <Button
                    label="Reset Password"
                    onclick={handleforgotpassword}
                    className="login-button"
                    // disabled={FormHasError}
                />
                }
                <div className="forgot-password-div">
                    {!forgotpassword &&<>
                    <span className="forgot-password" onClick={()=>{handlenewregistration(); seterrorMessage("");
                    }}> New Registration?</span>
                <span className="forgot-password" onClick={()=>{
                    setforgotpassword(true);
                    seterrorMessage("");
                }

                }> Forgot Password?</span>
                </>
                    }
                    {forgotpassword &&
                    <span className="forgot-password" onClick={()=>{
                        setforgotpassword(false);
                        seterrorMessage("");
                    }
                    }
                        > Login?</span>
                    }
                </div>
                {successMessage && (
          <div className="success-message">
          <p className="loginmessage">
            {successMessage}
          </p>
          </div>
        )}
        
        {errorMessage && (
          <div className="error-message">
          <p className="loginmessage">
            {errorMessage}
          </p>
          </div>
        )}
                {isloading && (
  <div className="loading-overlay">
    <div className="spinner"></div>
  </div>
)}
            </form>

            {/* <ColorRing
  visible={true}
  height="80"
  width="80"
  ariaLabel="color-ring-loading"
  wrapperStyle={{}}
  wrapperClass="color-ring-wrapper"
  colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
  /> */}
        </div>
    );
};
export default Login;
