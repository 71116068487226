import React, { useState } from 'react';
import './button.scss';

const Button = (props) => {

    return (
<>
<div className='ibutton'>
<button onClick={props.onclick} className={props.className} disabled={props.disabled}>
  {props.label}
</button>
</div>

</>
    );
};
export default Button;
